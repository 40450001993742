import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Dropdown} from 'primereact/dropdown';
import {ProgressSpinner} from 'primereact/progressspinner';

import {EmailHeader, FullEmail} from './EmailUtil';
import {NewEmail} from './NewEmail';
import ShareTo from './ShareTo';
import BaseEmails from './BaseEmails';
import {AddTag} from './Tag';
import {set_mkroom_clients} from './Persons';
import {ModalCommand, MoreMenu, Spacer, Focusable} from './Utils';

import {ldb, log, go, go_url, foreach_list, set_order, regcomp, gen_xid,
	list_rm_item, list_next_id, reset_page, init_list, get_item,
	get_room, get_list, edate, growl, api, mbox_move_perm,
	mbox_read_perm, mbox_write_perm, is_ticket_org,
	get_all_rooms_with_client_email, gen_email_summary,
	get_from_person_from_env, get_other_rooms_from_rids_csv} from './Lib';

// TBD: Share used to be called Transfer... Changed UI, but internal still
// 	called Transfer

//-------------- Help About Share ---------------

const AboutTransfer = props => (
<Dialog header="About Unshared and Shared Emails" visible={true}
	width="350px" modal={true}
	onHide={ e => props.close() }
	>
<div>
	<dl>
	<dt>My Unshared Emails</dt>
	<dd>Emails in <b>your</b> Inbox from this client</dd>
	<dt>Shared Emails</dt>
	<dd>Emails in a staff-wide <b>Shared</b> Inbox 
		related to this client</dd>
	<dt>Action: Share</dt>
	<dd>
	1. Selected email is <b>copied</b> to Common inbox.
	<br/>
	2. Selected email is <b>moved</b> to Client folder within your Inbox.
	</dd>

	<dt>Action: Mark Private</dt>
	<dd>
	1. Selected email is left as is: 
		Not copied to Common inbox and 
		not moved to client folder within in your inbox.
	<br/>
	2. Once you mark it, that email will not show up in this list.
	</dd>
	</dl>
</div>
</Dialog>)

class TransferConfirm extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, bulk_move, par} = this.props;
		const room = get_room(rid);
		const working = false;
		this.unshared_par = par;
		
		this.state = {rid:0, working};
		
		window.g_TransferConfirm = this;
	}

	proceed(unsh) {
		unsh.transfer_command();
		this.props.close();
	}

	render() {
		const {item, rid, bulk_move, par} = this.props;
		const unsh = par;
		
		let other_room_list = par.gather_other_client_rooms();
		
	 	return (
		<ModalCommand par={this}
			className="modal-35-command"
			header="Share Confirm - Multi-Room Client">

		{other_room_list.map((item, i) => <div key={"mrc_" + i}>
			<div>
			<b>{item.name}</b> is also in the following room(s).
			</div>
			<div>
			<br/>
			Share there, or proceed?
			</div>

			<ul>
				{item.rooms.map((room, j) => <li key={"mrc_room" + j}>
					<Link to={go_url('room', room.id, 'unshared')}>
						{room.name}
					</Link>
				</li>)}
			</ul>
		</div>)}
		
	{!this.state.working && <div className="p-grid" >
		<div className="p-col-12">
			<Button label="Proceed with Current Room"
				icon="pi pi-directions"
				onClick={() => this.proceed(unsh)}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</ModalCommand>
		)
	}
}


const pending_options = [
	{label: 'Client Emails', value: 'client_emails'},
	{label: 'Private Emails', value: 'private_client_emails'},
	{label: 'Main Inbox', value: 'inbox_emails'},
	{label: 'Clients Only', value: 'clients_only'},
];

const mailbox_options = [
	{label: 'Non-Rooms Emails', value: 'emails_without_rooms'},
	{label: 'Main Inbox', value: 'inbox_emails'},
	{label: 'All Emails', value: 'all_emails'},
	{label: 'Sent Emails', value: 'sent_emails'},
	{label: 'TagInbox Sent Emails', value: 'clientwin_sent_emails'},
	{label: 'Trash', value: 'trash_emails'},
];

function share_one(rid, iid) {
	const bid = gen_xid();	// batch id, used in progress bar.
	const args = {cmd: 'transfer_email', ids: [iid], rid, bid};
	api( args );
	window.g_progCircle.add(bid, 
		"Share Email in Room: " + get_room(rid).name);
	const env = get_item(rid, 'unshared', iid);
	env._being_moved = true;
}

//-------------- Tab: My Emails ---------------

// Called when we receive a multicast 'already_moved' because
//	another staff member moved an email we're considering.
//	remove it for now, move it to shared later.

class UnsharedEmails extends BaseEmails {
	constructor(props) {
		super(props);
		regcomp(this, 'unshared');
	}

	//---- Transfer Message

	transferred = () => {
		this.setState({busy:false});
	}

	transfer_all_command = () => {
		const {rid}  = this.props;
		const items = this.get_item_list();
		const ids = items._idlist;
		const args = {cmd: 'transfer_email', ids, rid};
		this.setState({busy: true});
		api( args, this.transferred );
	}

	transfer_command = () => {
		//return growl("Temporarily Disabled... Work in progress");
		log('email', '========Share-----------');

		const {rid}  = this.props;
		log('email', '========Share-----------', rid);
		let ids = this.get_checked_ids({'mark_as_moved': false});
		log('email', '========Share-----------', rid, ids);
		const items = this.get_item_list();
		if (!ids.length)
			return growl("Please select some items");
		
		let other_room_list = [];
		let private_count = 0;
		ids.forEach(function(id) {
			const env = items._items[id];
			if (env.is_private) {
				private_count++;
			}
		});

		if (private_count > 0) {
			return growl("You have selected " + private_count + " email(s) that have been marked as private. Private emails cannot be shared. Please mark them as non-private if you would like to share them");
		}
		
		ids = this.get_checked_ids();
		
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {cmd: 'transfer_email', ids, rid, bid};
		this.setState({busy: true});
		api( args, this.transferred );

		window.g_progCircle.add(bid, 
			"Move to Common in Room: " + get_room(rid).name,
				ids.length);
	}

	privated = (error, db, resp) => {
		this.setState({busy: false});
		//this.redir_iid();
	}

	private_command = () => {
		if (this.state.busy)
			return;

		const {rid} = this.props;
		const ids = this.get_checked_ids();
		if (!ids.length)
			return growl("Please select some items to be marked as private");
		const args = {cmd: 'unshared_private', ids, rid};
		this.setState({busy: true});
		api( args, this.privated, this.privated );
	}

	single_private = (iid) => {
		const {rid}  = this.props;
		const ids = [iid];
		const args = {cmd: 'unshared_private', ids, rid};
		this.setState({busy: true});
		api( args, this.privated, this.privated );
	}
	
	nonprivated = (error, db, resp) => {
		this.setState({busy: false});
		//this.redir_iid();
	}

	nonprivate_command = () => {
		if (this.state.busy)
			return;

		const {rid} = this.props;
		const ids = this.get_checked_ids();
		if (!ids.length)
			return growl("Please select some items to be marked as non-private");
		const args = {cmd: 'unshared_nonprivate', ids, rid};
		this.setState({busy: true});
		api( args, this.nonprivated, this.nonprivated );
	}

	single_nonprivate = (iid) => {
		const {rid}  = this.props;
		const ids = [iid];
		const args = {cmd: 'unshared_nonprivate', ids, rid};
		this.setState({busy: true});
		api( args, this.nonprivated, this.nonprivated );
	}

	about_command = args => <AboutTransfer {...args} />

	//---- Render

	list_toolbar = () => {
		const list = this.get_item_list();
		const page = list._flags.page;
		
		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();
		
		const items = [
		{
			label: 'Private',
			icon:"fa fa-fw fa-minus-square-o",
			tooltip:"Mark Private, Not for Common",
			command: () => this.private_command(),
		},
		{
			label: 'Non-Private',
			icon:"fa fa-fw fa-plus-square-o",
			tooltip:"Mark Non-Private, Not for Common",
			command: () => this.nonprivate_command(),
		},
		{
			label: 'Share To...',
			icon:"fa fa-fw fa-share-square-o",
			command: this.share_to_multiple,
			className: write_perm ? '' : 'hide',
		},
		{
			label: 'About Pending',
			icon:"pi pi-fw pi-info",
			command: () => this.select_command('about'),
		},
		      ];
		      
	const left = (
	<React.Fragment>
		<i className="fa fa-fw fa-envelope-o"></i>
		{' '}
		<Dropdown value={page.kind}
			className="toolbar-dropdown pending-options"
	    		options={pending_options}
			onChange={e => this.reset(e.value, '')}
		/>
		{this.page_size(page)}
	</React.Fragment>
	);

	const right = (
	<React.Fragment>
		{write_perm && <Button label="Share" 
			icon="fa fa-fw fa-share-square-o"
			className="p-button-raised p-button-outlined"
			tooltip="Copy to Shared Mailbox"
			onClick={this.transfer_wrap}
		/>}
		{page.has_more && <Button label="More"
			className="p-button-raised p-button-text"
			icon="pi pi-fw pi-angle-double-down"
			tooltip="Show More Emails"
			onClick={this.fetch_page}
		/>}
		{<MoreMenu model={items} />}
	</React.Fragment>
	);
		return <Toolbar left={left} right={right} />;
	}

	share_to = () => {
		this.select_command('share_to');
	}
	
	gather_other_client_rooms = () => {
		let ids = this.get_checked_ids({'mark_as_moved': false});
		const items = this.get_item_list();
		
		const {rid} = this.props;

		let other_rooms_list = [];
		let checked_persons = [];

		try {
		ids.forEach(function(id) {
			const env = items._items[id];
			
			const fperson = get_from_person_from_env(env);

			if (!fperson)
				return;
			
			if (checked_persons.includes(fperson.id))
				return;
			
			let env_room_list = get_other_rooms_from_rids_csv(fperson, rid);
			
			checked_persons.push(fperson.id);

			if (env_room_list.length > 0) {
				other_rooms_list.push({'name': fperson.client_user.name, 'rooms': env_room_list});
			}
		});

		}
		catch(error) {
		}

		
		return other_rooms_list;
	}

	transfer_wrap = () => {
		let other_room_list = this.gather_other_client_rooms();
		
		if (other_room_list.length == 0)
			return this.transfer_command();
		
		this.select_command('transfer_confirm');
	}

	share_to_multiple = () => {
		const eids = this.get_checked_ids({'mark_as_moved': false});
		if (!eids.length)
			return growl("Please select some items");
		
		this.select_command('share_to_multiple');
	}

	detail_toolbar = () => {
		const {rid, iid} = this.props;
		
		const env = iid ? get_item(rid, 'unshared', iid) : null;

		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();
		
		const items = [
			{
			label: 'Print Email',
			icon:"pi pi-print",
			command: () => this.setState({print:true}),
			},
			{
			separator:true,
			},
			{
			label: 'Share To...',
			icon:"fa fa-fw fa-share-square-o",
			command: this.share_to,
			className: write_perm ? '' : 'hide',
			},
		      ];
	
	const left = (
	<React.Fragment>
		<i className="detail-pane-icon fa fa-fw fa-envelope-open-o"></i>
		{' '}
	</React.Fragment>
	);

	const right = (iid==0) ? null : (
	<React.Fragment>
		{ldb.data.org.settings.allow_sending_private_emails && env && env.is_private && <Button label="Reply"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-reply"
			tooltip="Reply All"
			onClick={() => go('room', rid, 'compose', iid,
				'private_reply')}
		/>}
		{ldb.data.org.settings.allow_sending_private_emails && env && env.is_private && <Button label="Reply All"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-reply-all"
			tooltip="Reply All"
			onClick={() => go('room', rid, 'compose', iid,
				'private_reply_all')}
		/>}
		{ldb.data.org.settings.allow_sending_private_emails && env && env.is_private && <Button label="Forward"
				className="p-button-raised p-button-text"
				icon="fa fa-fw fa-share"
				tooltip="Forward"
				onClick={() => go('room', rid, 'compose', iid, 
					'private_forward')}
		/>}
		{ldb.data.org.settings.allow_sending_private_emails && env && env.is_private && <Button label="Non-Private"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-unlock"
			tooltip="Mark as Non-Private"
			onClick={() => this.single_nonprivate(iid)}
		/>}
		{ldb.data.org.settings.allow_sending_private_emails && env && (!env.is_private) && <Button label="Private"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-lock"
			tooltip="Mark as Private"
			onClick={() => this.single_private(iid)}
		/>}
		{<MoreMenu model={items} />}
	</React.Fragment>
	);
		return <Toolbar left={left} right={right} />;
	}

	list_legend = (list) => {
		return (
		<div className="p-grid">
		    <div className="p-col-2 checkbox-col">
			<Checkbox checked={list._flags.is_check_all} 
			    onChange={e => this.check_all(e.checked)}
				/>
		    </div>
		    <div className="p-col-10 list-search">
		    	{this.search_bar()}
		     </div>
		</div>
		)
	}

	reply_command = args => <NewEmail {...args} title="Reply"
					is_reply={true} />
	forward_command = args => <NewEmail {...args} title="Forward"
					is_reply={true} is_forward={true} />
	share_to_command = args => <ShareTo {...args} par={this} />
	share_to_multiple_command = args => <ShareTo {...args} par={this} bulk_move={true} />

	transfer_confirm_command = args => <TransferConfirm {...args} par={this} />
}

//class DeleteEmail extends Component {
//	constructor(props) {
//		super(props);
//		this.par = this.props.par;
//	}
//
//	onDelete = () => {
//		const {iid, rid} = this.par.props;
//		const api_args = {
//			cmd: 'delete_env', 
//			eid: iid,
//			rid: rid,
//		}
//		api( api_args, this.deleted, this.deleted );
//		//this.setState({working:true});
//
//		this.props.close();
//	}
//
//	deleted = e => {
//		this.props.close(e);
//	}
//
//	render() {
//		return (
//			<ModalCommand header="Delete Email"
//			    onHide={this.props.close}
//			    className='modal-command delete-email'
//		    	>
//<div className="p-grid">
//	<label className="p-col-6">
//		Are you sure you want to delete this email?
//	</label>
//	<div className="p-col-6">
//		<Button label="Delete Email" icon="pi pi-check" 
//			onClick={this.onDelete}
//			style={{marginRight: '.25em'}}/>
//		<Button label="Cancel" icon="pi pi-times" 
//			onClick={this.props.close}
//			className="p-button-secondary"/>
//	</div>
//</div>
//	</ModalCommand>
//		);
//
//	}
//}

class MailboxEmails extends BaseEmails {
	constructor(props) {
		super(props);
		regcomp(this, 'mailbox');
	}

	//---- Render

	list_toolbar = () => {
		const {rid, iid} = this.props;
		const list = this.get_item_list();
		const page = list._flags.page;
		
		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();
		const move_perm = mbox_move_perm();
		
		let items = [
			{
				label: 'Toggle Bulk Mode',
				icon: "pi pi-check",
				command: this.toggle_bulk_mode,
			},
		];

		if (this.props.par.state.mailbox_email_bulk_mode) {
			if (move_perm || ldb.data.org.settings.auto_share_with_multiple_home_rooms) {
				items.push({
					label: 'Delete Emails',
					icon: "fa fa-fw fa-times",
					command: this.delete_multiple_emails,
					className: write_perm ? '' : 'hide',
				});
			}
			items.push({
				label: 'Share To...',
				icon: "fa fa-fw fa-share-square-o",
				command: this.share_to_multiple,
				className: write_perm ? '' : 'hide',
			});
		}
				
		const left = (
		<React.Fragment>
			<i className="fa fa-fw fa-envelope-o"></i>
			{' '}
			<Dropdown value={page.kind}
				className="toolbar-dropdown pending-options"
	    			options={mailbox_options}
				onChange={e => this.reset(e.value, '')}
			/>
			{this.page_size(page)}
		</React.Fragment>
		);
		
		const right = (
		<React.Fragment>
			{page.has_more && <Button label="More"
				className="p-button-raised p-button-text"
				icon="pi pi-fw pi-angle-double-down"
				tooltip="Show More Emails"
				onClick={this.fetch_page}
			/>}
			<Button label="New"
				className="p-button-raised p-button-text"
				icon="fa fa-fw fa-pencil-square-o" 
				tooltip="New Email"
				onClick={() => go('room', rid, 'compose', iid)}
			/>
			{write_perm && <MoreMenu model={items} />}
		</React.Fragment>
		);
		
		return <Toolbar left={left} right={right} />;
	}
	
	share_to = () => {
		this.select_command('share_to');
	}

	share_to_multiple = () => {
		const eids = this.get_checked_ids({'mark_as_moved': false});
		if (!eids.length)
			return growl("Please select some items");
		
		this.select_command('share_to_multiple');
	}

	detail_toolbar = () => {
		const {rid, iid} = this.props;
		
		const env = iid ? get_item(rid, 'unshared', iid) : null;
		
		const is_in_trash = env ? (ldb.data.folders._items[env.fid].name == ldb.data.mailbox.trash_folder_name) : false;

		const ticket_org = is_ticket_org();
		
		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();
		const move_perm = mbox_move_perm();
		
		const items = [
			{
			label: 'Print Email',
			icon:"pi pi-print",
			command: () => this.setState({print:true}),
			},
			{
			separator:true,
			},
			{
			label: 'Share To...',
			icon:"fa fa-fw fa-share-square-o",
			command: this.share_to,
			className: write_perm ? '' : 'hide',
			},
		      ];
		
		if (is_in_trash) {
			items.push({separator: true});
			items.push({
				label: 'Return to Inbox',
				icon:"fa fa-fw fa-share-square-o",
				command: this.return_to_inbox,
			},);
		}

		if (ticket_org) {
			items.push({separator: true});
			items.push({
				label: 'Create Ticket',
				icon:"fa fa-fw fa-ticket",
				command: this.create_ticket,
			},);
		}
		
		const left = (
			<React.Fragment>
				<i className="detail-pane-icon fa fa-fw fa-envelope-open-o"></i>
				{' '}
			</React.Fragment>
		);
		
		const right = (iid==0) ? null : (
			<React.Fragment>
			<Button label="Reply"
				className="p-button-raised p-button-text"
				icon="fa fa-fw fa-reply"
				tooltip="Reply"
				onClick={() => go('room', rid, 'compose', iid)}
			/>
			<Button label="Reply All"
				className="p-button-raised p-button-text"
				icon="fa fa-fw fa-reply-all"
				tooltip="Reply All"
				onClick={() => go('room', rid, 'compose', iid,
					'reply_all')}
			/>
			<Button label="Tag"
				className="p-button-raised p-button-text"
				icon="pi pi-fw pi-tag"
				tooltip="Add a Tag"
				onClick={() => this.select_command('tag')}
			/>
			<Button label="Forward"
				className="p-button-raised p-button-text"
				icon="fa fa-fw fa-share"
				tooltip="Forward"
				onClick={() => go('room', rid, 'compose', iid, 
					'forward')}
			/>
			{(move_perm || ldb.data.org.settings.auto_share_with_multiple_home_rooms) && <Button label=""
				className="p-button-raised p-button-text"
				icon="fa fa-fw fa-times"
				tooltip="Delete"
				className={write_perm ? '' : 'hide'}
				onClick={() => this.delete_email()}
			/>}
			{<MoreMenu model={items} />}
			</React.Fragment>
		);
		
		return <Toolbar left={left} right={right} />;
	}

	list_legend = (list) => {
		return (
		<div className="p-grid">
		    <div className="p-col-2 checkbox-col">
			{this.props.par.state.mailbox_email_bulk_mode && <Checkbox checked={list._flags.is_check_all} 
			    onChange={e => this.check_all(e.checked)}
				/>}
		    </div>
		    <div className="p-col-10 list-search">
		    	{this.search_bar()}
		     </div>
		</div>
		)
	}

	delete_email = () => {
		const {iid, rid} = this.props;
		const api_args = {
			cmd: 'delete_envs', 
			eids: [iid],
			rid: rid,
		}
		api( api_args );
		this.go_to_next_item();
	}

	deleted_multiple = () => {
		this.setState({busy:false});
	}

	delete_multiple_emails = () => {
		const {iid, rid} = this.props;
		const ids = this.get_checked_ids();
		if (!ids.length)
			return growl("Please select some items");
		
		const api_args = {
			cmd: 'delete_envs', 
			eids: ids,
			rid: rid,
		}
		this.setState({busy: true});
		api( api_args, this.deleted_multiple );
	}

	return_to_inbox = () => {
		const {iid, rid} = this.props;
		const api_args = {
			cmd: 'return_to_inbox_envs', 
			eids: [iid],
			rid: rid,
		}
		api( api_args );
		this.go_to_next_item();
	}

	create_ticket = () => {
		const {iid, rid} = this.props;
		
		const env = get_item(rid, 'unshared', iid);
		
		set_mkroom_clients(env);
		
		go('add', 'create_room');
	}
	
	toggle_bulk_mode = () => {
		const par = this.props.par;
		const new_value = par.state.mailbox_email_bulk_mode ? false : true;
		par.setState({mailbox_email_bulk_mode: new_value});
	}

	no_items_text = () => {
		return <div>There are no unshared emails</div>
	}

	new_email_command = args => <NewEmail {...args} title="New Email"
					is_reply={false} />
	reply_command = args => <NewEmail {...args} title="Reply"
					is_reply={true} />
	forward_command = args => <NewEmail {...args} title="Forward"
					is_reply={true} is_forward={true} />
	tag_command = args => <AddTag {...args} par={this} is_my_mailbox={true} />
	share_to_command = args => <ShareTo {...args} par={this} />
	share_to_multiple_command = args => <ShareTo {...args} par={this} bulk_move={true} />
	//delete_command = args => <DeleteEmail {...args} par={this} />
}

export {UnsharedEmails, MailboxEmails, share_one};
