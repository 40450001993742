import React, {Component, useEffect} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {InputTextarea} from 'primereact/inputtextarea';
import {Card} from 'primereact/card';
import {Dialog} from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';

import {lazyFocus, selFocus} from './Utils';
import {show_name} from './TaskUtil';
import {MyNotes} from './Summary';

import {ldb, log, go, go_url, api, edate3, resort, set_order, url2rid,
	url2room, pint, get_staff, get_first_name, cap,
	set_current_list_view, show_new_flag, show_tag_flag, go_room,
	get_most_recent_public_activity, room_category_options,
	get_new_flag_color, get_room, get_room_activity, svg_icon, em,
	g_newness, growl, is_favorite_room, is_return_later_room,
	get_room_my_notes, clean_email, map_list, clear_dict,
	local_storage_update_preferences, nofun, mbox_read_perm,
	room_set_new_flags, init_new_flags,
	} from './Lib';

// Globals

// detail pane tab list
const g_tabset = {
	unshared: {
		name: 'My Unshared Emails',
		icon: 'fa fa-fw fa-envelope', 
		title: 'Pending Email Activity',
	},
	shared: {
		name: 'Shared Emails',
		icon: 'fa fa-fw fa-envelope-open', 
		title: 'Common Email Activity',
	},
	task: {
		name: 'Tasks',
		icon: 'fa fa-fw fa-calendar-check-o',
		title: 'Tasks Activity',
	},
	chat: {
		name: 'Chat',
		icon: 'fa fa-fw fa-comments',
		title: 'Chat Activity',
	},
	summary: {
		name: 'About',
		icon: 'pi pi-fw pi-info-circle',
		title: 'About Activity',
	},
};
const g_tabs = Object.keys( g_tabset );

//------- Inline Editing of Category

class MyRoomCategory extends Component {
	constructor(props) {
		super(props);
		
		const {room} = props;

		this.state = {active: false, working: false, 
				category: room.category};
	}
	
	componentDidUpdate() {
		const {room} = this.props;
		const {active, category} = this.state;

		if (!active && category != room.category)
			this.setState({category: room.category});
	}
	
	save = e => {
		e.preventDefault();
		const {room} = this.props;
		const {category} = this.state;
		
		const rid = room.id;
		
		const args = {cmd: 'categorize_room', rid, category};
		api( args, this.saved, this.saved );
		this.setState({working:true});
	}

	saved = e => {
		this.setState({active:false, working:false});
		this.props.rooms_reload();
		//this.props.rooms_reload();
	}
	
	render() {
		const options = room_category_options();
		const {active, category, working} = this.state;

		if ((options === null) || (options.length == 0))
			return <div>{this.state.category}</div>;
		
		let display_category = this.state.category;
		if (!display_category)
			display_category = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

		return <div className="inline-edit">
			<Inplace active={active} 
			    className={"room-category-inplace" + 
					(active ? ' inplace-active' : '') }
			    onToggle={e => {
					this.setState({active: e.value});
				}}>
			<InplaceDisplay>
				<span>
					{display_category}
				</span>
			</InplaceDisplay>
			<InplaceContent>
				{working && <i className="pi pi-fw pi-spin pi-spinner"></i>}
				<Dropdown value={category}
					placeholder="Choose"
					options={options}
					onChange={ e => this.setState({category: e.value}) } 
				/>
				<Button label=""
					icon="pi pi-fw pi-check"
					onClick={this.save}
				/>
				<Button label=""
					icon="pi pi-fw pi-times"
					onClick={e => {
					  this.setState({active:false,
					   category:this.props.room.category});
					 }}
					  
				/>
			</InplaceContent>
			</Inplace>
		</div>
	}
}

//------------- Icons

function forme_icon(klass, title) {
	return <svg className={'svg-icon2 ' + klass} title={title} >
			<title>{title}</title>
			< text x={1} y={8}>
				for
			</text>
			< text x={1} y={16}>
				me
			</text>
		</svg>;
}

function NewIcon({room}) {
	const newness = room._flags._newness;
	let klass = 'new-' + newness;
	let title = 'New';
	let text = 'new';

	if (newness == g_newness.me) {
		title = 'New. Action Needed';
		return forme_icon(klass, title);
	}
	return svg_icon(klass, text, title);
}

function tag_icon(room) {
	const tagpris = room._flags._tagpris;

	room.info.__has_tags = {value: Object.keys(tagpris).length != 0};

	if (Object.keys(tagpris).length == 0) {
		return null;
	}
	
	let unread_tags = false;
	Object.keys(tagpris).forEach(function(key) {
		try {
			if (!ldb.data.tags._items[key].dt_viewed) {
				unread_tags = true;
			}
		} catch(error) {
		}
	});
	
	const klass = unread_tags ? ' unread-tag-room' : '';
	
	return <Link to={go_url('room', room.id, 'shared')}>
		<i className={"pi pi-fw pi-tag small" + klass}></i>
	</Link>;
}

function auto_move_icon(room) {
	if (!room.auto_move)
		return null;
	return  (
	<i 
		title="Auto Share: Unshared to Shared"
		className="pi pi-fw pi-replay auto-move small"
		>
	</i>
	);
}

function clients_list(room) {
	const root = room.clients;

	return (
		<div className="myrooms-clients">
		{map_list(root, function(client) {
			let buf = '';
			let sep = '';
			client.emails._order.forEach(function(eid) {
				const email = client.emails._items[eid].email;
				buf = buf + sep + email;
				sep = ', ';
			});
			
			return (<div>
				<i className="pi pi-fw pi-user small"></i>
				<span className="name">
					{client.name} 
				</span> <span className="email">
					({buf})
				</span>
			</div>);
		})}
		</div>
	);
}

function favorite_icon(room) {
	let klass="pi pi-fw pi-star room-setting-icon room-setting-icon-prominent small ";
	if (!is_favorite_room(room))
		klass += ' invisible';

	return <i className={klass}></i>;
}

function return_later_icon(room) {
	let klass="fa fa-fw fa-lg fa-bookmark room-setting-icon room-setting-icon-prominent small";
	if (!is_return_later_room(room))
		klass += ' invisible';

	return <i className={klass}></i>;
}

// fa-hand-o-right
// fa-inbox
// fa-exclamation-triangle
// fa-exclamation-circle

//------------- Flags by Room Name

function TabFlags({room, mobile}) {
	const gostop = tab => {
		const fn = e => {
			e.stopPropagation();
			go('room', room.id, tab);
		}
		return fn;
		// In Mobile Mode, clicking on tab flags, does a "double go"
		//	resulting in #/room/<rid>/tab/<rid>
		// causing errors in trying to fetch rid as env.
		//	because flag leads to #/room/<rid>/tab
		// and same event causing row click in Mobile to go to /<rid>/
		// Solution: e.stopPropagation();
	}
	return (
	<React.Fragment>
		{g_tabs.map( tab => {
			const val = room._flags._tabs[tab];
			const ctab = g_tabset[ tab ];
			let klass = ctab.icon + ' tab-new-' + val;

			if (!val)
				klass += ' invisible';

			const onClick = mobile ? gostop(tab) : nofun;

			return <Link to={go_url('room', room.id, tab)} 
					key={tab}
					onClick={onClick}
				>
					<i className={klass}></i>
			</Link>;
		})}
		{tag_icon(room)}
	</React.Fragment>
	)
}

//-------Main Room Name Row Display 

let g_last_category = '';
function new_category(room, sortby, index) {
	if (sortby == 'category') {
		if (index == 0) {
			g_last_category = room.category;
			return '';
		}
		if (index == 0 || room.category != g_last_category) {
			g_last_category = room.category;
			return ' new_room_category ';
		}
	}
	return '';
}

const MyRoomItem = ({rid, crid, index, sortby, parreload}) => {
	const room = ldb.data.rooms._items[rid];
	const sel = (rid == crid);
	const preview = ldb.data.rooms._flags._preview;
	const display_clients = ldb.data.rooms._flags._display_clients;
	
	let klass = index % 2 ? 'oddrow ' : 'evenrow ';
	if (sel)
		klass += 'sel sel-room ';
	
	// Don't show my mailbox room in ghost mode
	if (room.is_mailbox && (!mbox_read_perm())) {
		return null;
	}

	klass += new_category(room, sortby, index);

	const click2 = e => {
		// log('room', 'Click', room.id, e.target.dataset.go);
		log('room', 'Click', room.id, e.target, e.target.dataset);
		ldb.data.rooms._flags.crid = room.id;

		const dtv = room._flags._dt_visited;
		const now = new Date();
		room._flags._dt_visited = now;

		if (!preview) 
			go('room', room.id);
		else if (e.target.dataset.go == 'go')
			go('room', room.id);	// click on go icon
		else if (sel && dtv && (now - dtv) < 600)// double click
			go('room', room.id);
		else
			go('myrooms', room.id);
	}

	const go_icon = <i 
		className="pi pi-fw pi-arrow-circle-right iconsepleft goicon"
			title="go to room"
			data-go="go"
				></i>;

	return (
<div className={'p-grid ' + klass} >
	<div className='p-col-fixed rooms-icons'>
		<NewIcon room={room} />
		{favorite_icon(room)}
		{return_later_icon(room)}
		<TabFlags room={room} />
	</div>
	<div className='p-col rooms-name' onClick={click2} >
		{room.name}
		{auto_move_icon(room)}
		{sel && go_icon}
		{display_clients && clients_list(room)}
	</div>
	<div className={'p-col-fixed rooms-category small'}>
		{true && <MyRoomCategory room={room} par={this} 
				rooms_reload={parreload} 
					/>}
		{false && <ModCategory room={room} />}
		{false && room.category}
	</div>
</div>
	)
}

//----------- Search Bar for Text and Vars

function parse_value(orig) {
	const val = orig.toLowerCase();
	if (val == 'true' || val == 'yes')
		return true;
	if (val == 'false' || val == 'no')
		return false;

	if (val.match(/^\d+$/))
		return parseInt(val);

	return orig;
}

const MyRoomsMenuBar = ({reload, mobile}) => {
	const flags = ldb.data.rooms._flags;
	if (flags.sortby === undefined)
		flags.sortby = ldb.data.me.settings.rooms_sortby;
	if (mobile)
		flags.sortby = 'category';

	const [sortby, setSortby] = React.useState( flags.sortby );

	const options_sortby = [
		{label: 'Sort by Name', value:'name'},
		{label: 'Sort by New Updates', value:'act'},
		{label: 'Sort by Category', value:'category'},
		{label: 'Sort by Last Updated', value:'updated'},
		{label: 'Sort by Last Visited', value:'visited'},
	];

	const svars = ldb.data.rooms._flags._search_vars;

	const checks = [
		{ prop: '__for_me',
			icon: forme_icon('new-2', 'new'),
			title: 'New items for me',
			},
		{ prop: '__new',
			icon: svg_icon('new-1', 'new', 'New'),
			title: 'New items for others',
			},
		{ prop: '__favorite',
			icon: <i className="pi pi-fw pi-star"></i>,
			title: 'Favorite',
			},
		{ prop: '__return_later',
			icon: <i className="fa fa-fw fa-lg fa-bookmark"></i>,
			title: 'Bookmarked',
			},
		{ prop: '__has_tags',
			icon: <i className="fa fa-fw fa-lg fa-tag"></i>,
			title: 'Has Tags',
			},
	];

	const update = (prop, val) => {
		if (val)
			svars[prop] = {value: true, op: '='};
		else
			delete svars[prop];

		local_storage_update_preferences({rooms_search_vars:svars});
		reload();
	}

	const changeSort = value => {
		flags.sortby = value;
		setSortby(value);

		const new_settings = {'rooms_sortby': value};
		const args = {cmd: 'staff_settings_update', new_settings};
		api( args );
		ldb.data.me.settings.rooms_sortby = value;

		reload();
	}

	return (
<div className="rooms-menubar p-grid" >
	<div className="p-col-fixed rooms-checkboxes room-setting-icon">
	{checks.map((item, i) => {
		return (
	<React.Fragment key={i}>
		<Checkbox 
			tooltip={item.title}
			onChange={ e => update(item.prop, e.checked) }
			checked={svars[item.prop] !== undefined} 
		/>

		{item.icon}

	</React.Fragment>
		);
	})}

	{!mobile && <React.Fragment>
		<Checkbox 
			tooltip="Preview Pane"
			onChange={e => {flags._preview = e.checked; reload();}}
			checked={flags._preview}
		/>
		<i className="pi pi-fw pi-map"
			title="Preview Pane" >
		</i>
	</React.Fragment>}
	
	{!mobile && <React.Fragment>
		<Checkbox 
			tooltip="Display Clients"
			onChange={e => {flags._display_clients = e.checked; reload();}}
			checked={flags._display_clients}
		/>
		<i className="pi pi-fw pi-user"
			title="Display Clients in Each Room" >
		</i>
	</React.Fragment>}

	{em(1)}
	</div>

	<div className="p-col">
		&nbsp;
	</div>

	{!mobile && <RoomSearch reload={reload} />}

	{!mobile && <div className="p-col myrooms-sortby">
			<Dropdown className="myrooms-dropdown"
				value={sortby}
				options={options_sortby}
				onChange={ e => changeSort(e.value) }
				placeholder="Sort by"
			/>
		</div>
	}
	
</div>
	)
}

function parse_var_clauses(text) {
	const args = {};
	text && text.match(/\S+\b/g).forEach(phrase => {
		const m = phrase.match(/(\w+)(\W+)(\w+)/);
		if (m) {
			let [full, prop, op, value] = m;
			value = parse_value(value);
			log('room', 'parse', {full, prop, op, value});
			args[prop] = {value, op};
		}
	})
	log('room', 'parse.out', args);
	return args;
}

function init_var_buf(args) {
	const phrases = [];
	for (let prop in args) {
		const item = args[prop];

		phrases.push(prop + item.op + item.value);
	}
	return phrases.join(' ');
}

function RoomVarSearch({reload, refresh}) {
	// Sets rooms._flags._search_text and calls reload, when updated

	const root = ldb.data.rooms;
	const flags = root._flags;
	const ibuf = init_var_buf(flags._search_vars);

	const [search, setSearch] = React.useState(ibuf);

	const update = text => {
		setSearch(text);
		flags._buf_search_vars = text;
		flags._search_vars = parse_var_clauses(text);
		reload();
	}

	useEffect( () => {
		const sbuf = init_var_buf(flags._search_vars);
		if (sbuf != search)
			setSearch(sbuf);
	});

	const keyDown = e => (e.key === 'Escape' && update(''))

	log('room', 'RoomVarSearch', {refresh, search, ibuf});

	return (
		<div className="p-grid">
			<div className="p-col-1 ">
			</div>
			<div className="p-col-9 ">
	<InputText value={search} ref={lazyFocus}
		className="room-search" placeholder="Search Variables"
		onChange={e => setSearch(e.target.value)}
		onKeyDown={keyDown}
			/>
	{em()}
	<Button label='' icon='pi pi-fw pi-check'
			className="p-button p-button-secondary"
			onClick={e=> {
				update(search);
		}} />
	{em()}
	<Button label='' icon='pi pi-fw pi-times'
			className="p-button p-button-secondary"
			onClick={e=> {
				setSearch('');
				update('');
		}} />
			</div>

			<div className="p-col-2 ">

			</div>
		</div>
	);
}

function RoomSearch({reload, mobile}) {
	// Sets rooms._flags._seach_text and calls reload, when updated

	const root = ldb.data.rooms;
	const flags = root._flags;

	const [search, setSearch] = React.useState(flags._search_text);

	const update = text => {
		setSearch(text);
		flags._search_text = text;
		reload();
	}
	const keyDown = e => (e.key === 'Escape' && update(''))

	return (
		<div className="p-col-fixed rooms-search">
	<InputText value={search} ref={mobile? nofun: lazyFocus}
		className="room-search" placeholder="Search"
		onChange={e => update(e.target.value)}
		onKeyDown={keyDown}
			/>
	{em(0.25)}
	{search && <Button label='' icon='pi pi-fw pi-times'
			className="p-button p-button-secondary mobile-adjust"
				onClick={e=> {
					update('');
					if (!mobile)
						selFocus('.room-search');
			}} />}

	{!search && <Button label='' icon='pi pi-fw pi-search'
			className="p-button p-button-secondary mobile-adjust"
				disabled={true}
				onClick={e=> null}
			/>}
		</div>
	);
}

function propSearch(room) {
	if (room.hidden)
		return false;
	
	const svars = ldb.data.rooms._flags._search_vars;

	if (clear_dict(svars))
		return true;

	// for now ignore op. assume ==.
	for (const prop in svars) {
		const expect = svars[prop];
		const item = room.info[prop];

		// log('room', 'prop.match', room.id, prop, expect, item);

		if (item !== undefined && item.value == expect.value)
			return true;
	}
	return false;
}

//------------------ Room Legend 

const MyRoomsLegend = props => {

	return (
<div className="rooms-legend p-grid grey bold bline" >
	<div className='p-col-fixed rooms-icons'>
		Flags
	</div>
	<div className='p-col rooms-name'>
		Rooms ({props.count})
	</div>
	<div className='p-col-fixed rooms-category'>
		Category
	</div>
</div>
	)
}

//------------------ Room List 

function RoomList({crid, parreload}) {
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => {
		// setRefresh(refresh+1);
		// log('room', 'Rooms.refresh', refresh);
		parreload();
	}

	useEffect( () => {
		keep_showing('.sel-room');
	});

	init_new_flags();

	const root = ldb.data.rooms;
	const flags = root._flags;
	
	let num_overall_rooms = root._idlist.length;
	
	flags._filter_fn = propSearch;
	if (flags.sortby === undefined)
		flags.sortby = ldb.data.me.settings.rooms_sortby;

	set_order(root);
	resort(root);

	log('room', 'RoomList', crid, refresh, root._order);

	const col = flags._preview ? 'p-col-6 rooms-split-panel' : 'p-col-12';

	return (
	<div className={col + " rooms-panel"}>
		{false && <RoomVarSearch reload={reload} refresh={refresh} />}
		<MyRoomsMenuBar reload={reload} />
		<MyRoomsLegend count={root._order.length} />
		{(num_overall_rooms == 0) && <div className="room-list empty-room-list">
			You have not been assigned to a client yet
		</div>}
		{(num_overall_rooms > 0) && <div className={"room-list " + 
				(flags._preview ? 'preview' : 'no-preview')}>
			{root._order.map( (id, i) => <MyRoomItem 
				key={i} index={i} rid={id} crid={crid} 
				sortby={flags.sortby} parreload={parreload}
					/> )}
		</div>}
	</div>
	);
}

//--------------- Detail Pane Display

function field_prep(buf) {
	buf = buf.replace('_','');
	buf = buf.charAt(0).toUpperCase() + buf.slice(1);
	return buf;
}

function field_row(term, desc, key) {
	key = key || term;
	return (
	<div className="p-grid" key={key}>
		<div className="p-col-1">
		</div>
		<div className="p-col-2 field-name term">
			{term}
		</div>
		<div className="p-col-9 field-value">
			{desc}
		</div>
	</div>
	);
}

function show_triggers(triggers) {
	let buf = '';
	if (triggers.from.length)
		buf += 'From: ' + triggers.from.join(', ') + '  ';
	if (triggers.subject.length)
		buf += 'Subject: ' + triggers.subject.join(', ') + '; ';
	return buf;
}

function ModCategory({room}) {
	const [crid, setCrid] = React.useState(room.id);
	const [category, setCategory] = React.useState(room.category);
	const [mod, setMod] = React.useState(false);
	const update = val => {
		if (val) {
			setCategory(val);
			setMod(true);
		}
		else {
			setCategory(room.category);
			setMod(false);
		}
	}
	const save = e => {
		const args = {cmd: 'categorize_room', rid: room.id, category};
		api( args );
		setMod(false);
	}

	useEffect(() => {
		if (room.id != crid) {	// room changed
			setCrid(room.id);
			setCategory(room.category);
			if (mod)	
				setMod(false);
		}
	});


	const options = room_category_options();
	if ((options === null) || (options.length == 0))
		return room.category;

	return (
	<div className={'mod-category ' + (mod ? 'edit' : '')}>
		<Dropdown value={category}
			options={options}
			onChange={ e => update(e.value) } 
		/>
		{mod && <Button label=""
			icon="pi pi-fw pi-check"
			onClick={save}
		/>}
		{mod && <Button label=""
			icon="pi pi-fw pi-times"
			onClick={e => update(false)}
		/>}
	</div>
	);
}

function room_field_info(room, setEditMyNotes) {
	return (
<div className="field-info">
	<div className="term">
		Fields
	</div>

	{['_caption', '_flag', '_notes'].map( (field, i) => {
		let item = room.info[field];
		if (!item)
			return null;
		return field_row( field_prep(field), item.value, i);
	})}

	{room.triggers_json != '{}' && field_row('Triggers',
			show_triggers(room.triggers) )}
</div>
	);
}

function activity_dates(activity) {
	const newer = 
		window.g_moment(activity.dt_last_write).isAfter(
			window.g_moment(activity.dt_last_read) ) ?
		'newer' : '';
	return (
	<React.Fragment >
		<div className="p-col-1">
		</div>
		<div className="p-col-2 field-name">
			Updated
		</div>
		<div className={"p-col-3 small field-value " + newer}>
			{edate3( activity.dt_last_write )}
		</div>
		<div className="p-col-1">
		</div>
		<div className="p-col-2 field-name">
			Visited
		</div>
		<div className="p-col-3 small field-value">
			{edate3( activity.dt_last_read )}
		</div>
	</React.Fragment>
	);
}

function staff_name(sid, i=0) {
	const sname = sid ? get_staff(sid).name : 'System';

	let klass = 'act-staff'; 
	if (sid == ldb.data.me.id)
		klass += ' act-me';
	else if (sid == 0)
		klass += ' act-system';

	return <span title={sname} className={klass} key={i}>
			{get_first_name(sname)}
	</span>;
}

function client_name(cuser) {
	let klass = 'act-client'; 
	let emails = [];

	map_list(cuser.emails, ceid => {
		if (ceid.priority >= 0)
			emails.push(ceid.email);
	});

	if (cuser.smstel)
		emails.push(cuser.smstel);

	return <div className={klass} key={cuser.id}>
			{cuser.name} 
			<span className="emails">
				({emails.join(', ')})
			</span>
	</div>;
}

function act_env_name(item, i) {
	const name = item.name || item.email;
	let klass = 'act-email-person';
	if (clean_email(item.email) == clean_email(ldb.data.me.email))
		klass += ' act-email-me';
	return <span key={i} title={item.email} className={klass} >
		{name}
	</span>;
}

function act_env_name_list(data, kind, i) {
	const nlist = data[kind];

	if (nlist.length == 0)
		return null;

	return (
	<React.Fragment key={i}>
		<div className="p-col-1">
		</div>
		<div className="p-col-2 act-label">
			{cap(kind)}:
		</div>
		<div className="p-col-9">
			{nlist.map(act_env_name)}
		</div>

	</React.Fragment>
	);
}

function show_act_tag(act, room, tab) {
	return (
	<div className="p-col-12">
	<div className="p-grid act-email">
		<div className="p-col-1">
		</div>
		<div className="p-col-2 act-label">
			Note:
		</div>
		<div className="p-col-9">
			{act.summary}
		</div>
	</div>
	</div>
	);
}

function show_act_email(act, room, tab) {
	const {data} = act;

	if (!('to' in data))
		return null;

	return (
	<div className="p-col-12">
	<div className="p-grid act-email">
		{['from','to','cc','bcc'].map( (kind, i) => 
				act_env_name_list(data, kind, i) )}

		<div className="p-col-1">
		</div>
		<div className="p-col-2 act-label">
			Subject:
		</div>
		<div className="p-col-9">
			{act.summary}
		</div>
	</div>
	</div>
	);
}

function show_act_task(act, room, tab) {
	const {data} = act;

	return (
	<div className="p-col-12">
	<div className="p-grid act-email">
		<div className="p-col-1">
		</div>
		<div className="p-col-2 act-label">
			Subject:
		</div>
		<div className="p-col-9">
			{act.summary}
		</div>

		<div className="p-col-1">
		</div>
		<div className="p-col-2 act-label">
			To:
		</div>
		<div className="p-col-9">
			{staff_name(data.to)}
		</div>
	</div>
	</div>
	);
}

function show_act_detail(act, room, tab) {
	if (act.verb == 'tagged' || act.verb == 'completed tag')
		return show_act_tag(act, room, tab);
	else if (tab == 'shared' || tab == 'unshared')
		return show_act_email(act, room,tab);
	else if (tab == 'task')
		return show_act_task(act, room,tab);
	
	return (
	<React.Fragment>
		<div className="p-col-2">
		</div>
		<div className="p-col-10 act-what">
			<span className="act-verb">
				{act.verb}:
			</span>
			<span className="act-summary">
				{act.summary}
			</span>
		</div>
	</React.Fragment>
	);
}

function tag_to(act) {
	if (act.verb =='tagged' && act.to.length)
		return staff_name(act.to[0], 1);

	return null;
}

function show_new_act(act, room, tab, i) {
	// log('room', 'tab_info.show_act', act, room, tab, i);
	const uniqto = [...new Set(act.to)];

	return (
	<div className="p-grid act" key={i} 
			onClick={e => go('room', room.id, tab, act.id)} >

		<div className="p-col-1 xxsmall">
			#{act.id}
		</div>
		<div className="p-col-8">
			{staff_name(act.by)}
			{' '}
			{act.verb}
			{tag_to(act)}
		</div>
		<div className="p-col-3 act-when">
			{ edate3(act.when) }
		</div>

		{show_act_detail(act, room, tab)}

		
	</div >
	);
}

function group_acts_by_id( new_json ) {
	const news = JSON.parse( new_json );
	const id_acts = {};
	news.forEach( item => {
		if (!(item.id in id_acts))
			id_acts[item.id] = [];
		id_acts[item.id].push( item );
	});
	const acts = [];
	for (const [id, items] of Object.entries(id_acts))
		items.forEach(item => acts.push(item));
	// log('room', 'tab_info.group', news, id_acts, acts);
	return acts;
}

function any_activity(room) {
	for (const [key, item] of Object.entries(room.room_activity._items))
		if (item.new_json != '[]')
			return true;
	return false;
}


function RoomTabInfo({room}) {
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);
	const lazyReload = () => setTimeout(reload, 500);

	const clear = tabs => {
		const args = {cmd: 'mod_room',
				op: 'clear_room_activity', 
				rid: room.id, 
				val: tabs};
		api( args, lazyReload );
		return false;
	};

	const add_room_notification = (tabs, color) => {
		const val = {'tabs': tabs, 'color': color};
		
		const args = {cmd: 'mod_room',
				op: 'add_room_activity', 
				rid: room.id, 
				val: val};
		api( args, lazyReload );
		return false;
	};

	// log('room', 'tab_info.room', room);

	return (
<div className="p-grid">
	<div className="p-col-4 ">
	</div>
	<div className="p-col-3 tab-hdr">
		New Activity: Preview
	</div>
	<div className="p-col-5 col-right">
		{any_activity(room) && <Button label="Clear All"
			onClick={e => clear(g_tabs.join(','))}
			className="p-button p-button-secondary"
		/>}
	</div>
	<div className="p-col-12">
	{g_tabs.map( (tab, i) => {
		const ctab = g_tabset[tab];
		const activity = get_room_activity(room, tab);
		if (!activity)
			return null;

		// log('room', 'tab_info.activity', activity);

		const acts = group_acts_by_id( activity.new_json );

		let icon = ctab.icon + ' tab-new-' + activity._flags._newness;

		// log('room', 'tab_info.acts', acts);

		return (
		<div className="p-grid act-tab" key={i}>
			<div className="p-col-3 tab-name">
				<i className={icon}></i>
				{' '}
				<Link to={go_url('room', room.id, tab)}>
					{ctab.name}
				</Link>
			</div>
			<div className="p-col-2 tab-name">
			  {acts.length > 0 && 
				<Link to='#' onClick={e => clear(tab)}
				>
					Clear Notifications
				</Link>
			  }
			</div>
			<div className="p-col-2 tab-name">
			</div>
			<div className="p-col-2 tab-name">
			</div>
			<div className="p-col-3 tab-name">
				{activity.is_full && <span>
				  <span className="lightYellow">
					notifications full. 
				</span>
					{em()}
					visit tab for all new items.
				  </span>}
			</div>

			{activity_dates(activity)}

			<div className="p-col-1">
			</div>
			<div className="p-col-11">
			{acts.map( (new_act, i) => 
				show_new_act(new_act, room, tab, i))}
			</div>
		</div >
		);
	})}
	</div>
</div>
	);
}

function people(room, setEditMyNotes) {
	const my_notes = get_room_my_notes(room);
	return (
	<div className="p-grid">
		<div className="p-col-2 term">
			Clients:
		</div>
		<div className="p-col-10">
			{map_list(room.clients, client_name)}
		</div>
		<div className="p-col-2 term">
			Staff:
		</div>
		<div className="p-col-10">
			{room.staffs._idlist.map(staff_name)}
		</div>
		<div className="p-col-2 term">
			<Link to='#' onClick={e=>setEditMyNotes(true)}>
				My Notes:
			</Link>
		</div>
		<div className="p-col-10 my-notes">
			{my_notes}
		</div>
	</div>
	);
}

function RoomDetail(props) {
	const {crid, parreload} = props;
	const room = get_room(crid);
	const [editMyNotes, setEditMyNotes] = React.useState(false);

	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);
	const lazyReload = () => setTimeout(reload, 500);
	
	const add_room_notification = (tabs, color) => {
		const val = {'tabs': tabs, 'color': color};
		
		const args = {cmd: 'mod_room',
				op: 'add_room_activity', 
				rid: room.id, 
				val: val};
		api( args, lazyReload );
		return false;
	};

	if (!crid) {
		return (
		<div className="rooms-panel">
		<div className="p-grid">
			<div className="p-col-12 botborder">
				<h3>
				Click on a Name in Room List to View
				</h3>
			</div>
			<div className="p-col-12 ">
				Double Click to Go to Room directly.
			</div>
		</div>
		</div>
		);
	}
	const is_favorite = is_favorite_room(room);
	const return_later = is_return_later_room(room);

	const toggle_favorite_badge = () => {
		const new_value = is_favorite ? false : true;
		const args = { cmd: 'mod_badge', rid: crid, field: 'favorite', value: new_value };

		api( args, (error, db) => {
			parreload();
		});
	};

	const toggle_return_later_badge = () => {
		const new_value = return_later ? false : true;
		const args = { cmd: 'mod_badge', rid: crid, field: 'return_later', value: new_value };

		api( args, (error, db) => {
			parreload();
		});
	};
	
	const favorite_icon = is_favorite ? 'pi-star' : 'pi-star-o';
	const return_later_icon = return_later ? 'fa-bookmark' : 'fa-bookmark-o';

	const f_action_word = is_favorite ? 'Unmark' : 'Mark';
	const r_action_word = return_later ? 'Unbookmark' : 'Bookmark';

	return (
<div className="p-col-5 rooms-panel rooms-split-panel">
<div className="p-grid">
	<div className="p-col-1 botborder bold">
		<Link to={go_url('room', room.id)} >
			Visit
		</Link>
	</div>
	<div className="p-col-9 botborder">
		<h3>{room.name}</h3>
	</div>
	<div className="p-col-2 botborder room-detail-icons">
		<i className={"pi pi-fw " + favorite_icon}
			title={f_action_word + " as a favorite room"}
			onClick={toggle_favorite_badge}>
		</i>
		<i className={"fa fa-fw fa-lg " + return_later_icon}
			title={r_action_word + " as a room to return to later"}
			onClick={toggle_return_later_badge}>
		</i>
		<i className="fa fa-fw fa-lg fa-sticky-note-o"
			title="Edit my notes for the room"
			onClick={e => setEditMyNotes(true)}>
		</i>
		{editMyNotes && <MyNotes room={room} 
					setEditMyNotes={setEditMyNotes} />}
	</div>
</div>
<div className="p-grid room-detail">
	{ldb.data.org.system_org && <div className="p-col-12 botborder">
		<div className="p-grid act-tab">
			<div className="p-col-2">
			</div>
			<div className="p-col-2">
				<i className="fa fa-fw fa-envelope-open tab-new-1 pointer" onClick={e => add_room_notification('shared', 'green')}></i>
			</div>
			<div className="p-col-2">
				<i className="fa fa-fw fa-calendar-check-o tab-new-1 pointer" onClick={e => add_room_notification('task', 'green')}></i>
			</div>
			<div className="p-col-2">
				<i className="fa fa-fw fa-comments tab-new-1 pointer" onClick={e => add_room_notification('chat', 'green')}></i>
			</div>
			<div className="p-col-2">
				<i className="pi pi-fw pi-info-circle tab-new-1 pointer" onClick={e => add_room_notification('summary', 'green')}></i>
			</div>
		</div>
		<div className="p-grid act-tab">
			<div className="p-col-2">
				<i className="fa fa-fw fa-envelope tab-new-2 pointer" onClick={e => add_room_notification('unshared', 'red')}></i>
			</div>
			<div className="p-col-2">
				<i className="fa fa-fw fa-envelope-open tab-new-2 pointer" onClick={e => add_room_notification('shared', 'red')}></i>
			</div>
			<div className="p-col-2">
				<i className="fa fa-fw fa-calendar-check-o tab-new-2 pointer" onClick={e => add_room_notification('task', 'red')}></i>
			</div>
			<div className="p-col-2">
				<i className="fa fa-fw fa-comments tab-new-2 pointer" onClick={e => add_room_notification('chat', 'red')}></i>
			</div>
			<div className="p-col-2">
				<i className="pi pi-fw pi-info-circle tab-new-2 pointer" onClick={e => add_room_notification('summary', 'red')}></i>
			</div>
		</div>
	</div>}
	<div className="p-col-12 botborder">
		{people(room, setEditMyNotes)}
	</div>
	<div className="p-col-12 botborder">
		<RoomTabInfo room={room} />
	</div>
	<div className="p-col-12 botborder">
		{room_field_info(room, setEditMyNotes, parreload)}
	</div>
	<div className="p-col-12">
	</div>
</div>
</div>
	);
}

// -------- Rooms

function get_crid() {	// current room id
	return ldb.data.rooms._flags._crid || 0;
}
function set_crid(rid) {
	return ldb.data.rooms._flags._crid = rid;
}

function Rooms(props) {
	let {rid, command} = props.match.params;
	const defval = rid || ldb.data.rooms._flags.crid || 0;
	const [crid, setCrid] = React.useState(defval);
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => {
		setRefresh(refresh+1);
		log('room', 'Rooms.refresh', refresh);
	}

	useEffect(() => {
		// mount
		// window.g_rooms_refresh = reload; // set below
		// log('room', 'Rooms.Mount+++');
		return () => {
			// unmount
			window.g_rooms_refresh = null;
			// log('room', 'Rooms.unMount---');
		}
	}, []);

	log('room', 'Rooms', crid, props, rid, command, refresh);
	window.g_rooms_refresh = reload;

	const preview = ldb.data.rooms._flags._preview;

	if (rid !== undefined && rid != crid) {
		setCrid( pint( rid ) );
		return null;
	}

	if (command == 'refresh') {
		reload();
		go('myrooms', crid);
		return null;
	}

	return (
	<div className="p-grid myrooms">
		<RoomList {...props} crid={crid} parreload={reload} />
		{preview && 
		   <RoomDetail {...props} crid={crid} parreload={reload} />
		}
	</div>
	);
}

// -------- Mini Rooms

function miniroom(rid, i, sel) {
	const room = get_room(rid);
	const klass = sel == rid ? 'mini-sel' : '';

	return (
	<li key={i} >
		<Link to={go_url('room', rid)} className={klass} >
			&#x25b8;
			{' '}
			{room.name}
			{' '}
			<NewIcon room={room} />
			<div className="my-room-sidebar-icons">
			</div>
		</Link>
	</li>
	);
}

function keep_showing(selector) {
	const sel = document.querySelector(selector);
	if (!sel)
		return;

	const rect = sel.getBoundingClientRect();
	
	// log('room', 'keep_showing', sel, rect);

	// already in viewport?
	if ( rect.top >= 0 && rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || 
				document.documentElement.clientHeight) && 
		rect.right <= (window.innerWidth || 
				document.documentElement.clientWidth) 
			    )
		return;

	sel.scrollIntoView(false);
}

class MiniRooms extends Component {
	constructor(props) {
		super(props);

		this.state = {nrefresh:0};

		window.g_miniRooms = this;
	}
	
	componentDidUpdate() {
		const room = url2room();
		if (room)
			window.g_setTitle(room.name);	

		keep_showing('.mini-sel');
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	refresh = () => this.setState({refresh: this.state.nrefresh+1})

	render() {
		const root = ldb.data.rooms;
		root._flags._search_text = this.props.search;
		set_order(root);
		resort(root);
		const order = root._order;

		if (root._idlist.length == 0)
			return 'None';
		
		const sel = url2rid();

		log('room', 'MiniRooms Refresh');

		return (
		<ScrollPanel className="mini-list">
		<ul>
			{order.map((rid, i) => miniroom(rid, i, sel))}
		</ul>
		</ScrollPanel>
		);
	}
}

export {Rooms, MiniRooms, RoomSearch, MyRoomsMenuBar, TabFlags, propSearch};
