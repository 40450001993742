import React from 'react';
import {Link} from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import {ldb, log, go, go_url, get_room, get_list, get_item, list_rm_item, 
	api, get_first_id, go_replace, list_has_ele, is_in_viewport, 
	foreach_list, growl, set_current_list_view,
	set_order, resort, remove_flag, server_remove_flag,
	show_new_flag, get_room_activity, env_has_my_unread_tags} from './Lib';
import {Commandable} from './Utils';

window.g_idebug = 0;


//-------------- List View : 2 col, list and detail ---------------
// Base class for many tabs.
// Input Props:
// 	rid, tab, iid
// Left col: shows a list of items, each clickable, to hash url that includes
// 	selected item.
// Right col: shows detail of selected item.


// This is a core foundational Base Class component, 
//			used in shared/pending/tasks
// Context:
//    1. Tasks: All downloaded; All active tasks are fetched on Login
//    2. Emails: On Demand: ONLY fetched when you visit the room+tab first
//    3. You only Paginate "downwards" from the time you logged in.
//	 All new items in each of your rooms are multicasted down to you.
//    4. Currently IDs are used for pagination, in future it may be time.
//
// IMPORTANT: when user navigates and clicks on an list item, URL changes!
//	When URL changes, this component DidUpdate gets called, 
//		component itself is NOT unmounted and a new one created.
//		So do NOT keep items in state that may be irrelevant
//		when URL changes to another item, another tab, another room
//		and comes back.
//		URL also changes with sub commands: search/compose/reply/...
//
// Data Structures:
//    1. Each tab section is initialized when room data arrives (in Lib/postfix)
//    		(it is never null/undefined
//    2. Page in Flags track pending status
//		ldb.data.rooms._items[27].unshared._flags.page
//			last_dt, _fetching_page, _fetching_iids[], ...
//    Input: rid, tab, iid, command
//
// URLs:
//    1. /#/room/27/shared/-1	: fetch page, go to first item
//	     may go to:  /#/room/27/shared/321   after page is fetched
//
// Logic:
//    1. if iid is -1 (means go to first item), 
//	 and we never fetched (for this room+tab), fetch a page.
//    2. TBD complete this..

window.g_listView_save = {clicked_checkbox: false};

class ListView extends Commandable {
	constructor(props) {
		super(props);
		
		const {tab, rid, iid, command} = this.props;
		// iid < 0, means show first item in list when fetched
		// iid == 0, means empty list
		// iid > 0, means valid item, show if/after fetched

		// Data states : 
		//	FetchPage, FetchItem, '' (Current)
		// don't store it in state, see above

		this.state = {
			nrefresh: 0, 
			search_text: '', prev_search_text: '',
			search_mod: false,
			print:false,
		};
		window.g_listView = this;
		this._isMounted = false;
	}

	// Important, these get from props, not state!
	get_item_list = () => {
		const {rid, tab} = this.props;
		return get_list(rid, tab);
	}

	get_current_item = () => {
		const {rid, tab, iid} = this.props;
		return get_item(rid, tab, iid);
	}

	get_page = () => this.get_item_list()._flags.page

	calc_data_state = () => {
		const item_list = this.get_item_list();
		const item = this.get_current_item();
		const {iid} = this.props;

		let data_state = '';
		if (item_list._flags._new)
			data_state = 'FetchPage';
		else if (!item && iid < 0)
			// last item was transferred from unshared. fetch more.
			data_state = 'FetchPage'; 
		else if (!item && iid > 0)
			data_state = 'FetchItem';
		else if (item && this.is_email() && !item.body)
			data_state = 'FetchItem';

		log('listview', 'data_state', {item, iid, data_state, item_list});

		return data_state;
	}

	comp_log = msg => {
		const {rid, tab, iid, command, g_viewCount} = this.props;
		log('listview', msg, {rid, tab, iid, command, g_viewCount});
	}

	componentDidMount() {
		this._isMounted = true;
		this.comp_log('++Mount');

		// didUpdate doesn't get called first time..
		// 	we call it explicityly
		this.componentDidUpdate();
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.comp_log('--UnMount');

		set_current_list_view(null);
	}

	componentDidUpdate() {
		// Init.. or props changed .. or just status changed.
		// Initiate all Network Transactions as appropriate.

		const {rid, tab, iid} = this.props;

		set_current_list_view(this);

		const room = get_room(rid);
		const has_new_activity = show_new_flag(room, tab);

		remove_flag(room, tab);
		this.do_search_if_needed();

		if (this.state.print)
			this.setState({print:false});
		
		if (tab == 'task' && has_new_activity)
			server_remove_flag(room, tab);

		// Sets data state in this.state and acts on it
		const data_state = this.calc_data_state();
		log('listview', 'didUpdate: set state', {data_state});
		
		let fetched_item = false;
		
		if (data_state == 'FetchPage') {
			this.fetch_page();
		} else if (data_state == 'FetchItem') {
			this.fetch_item();
			fetched_item = true;
		} else if (room._flags._tabs[tab]) {
			this.clear_activity(room, tab);
		}
		
		// If an email we have already fetched before has unread tags,
		// we need to mark them as read on the server.
		try {
			if ((tab == 'shared') && (!fetched_item)) {
				const env = get_item(rid, tab, iid);
				if (env) {
					const has_unread_tags = env_has_my_unread_tags(env);
					if (has_unread_tags) {
						const cmd = 'mark_viewed_tags';
						const args = {cmd, rid, 'eid': iid};
						api( args );
					}
				}
			}
		} catch (error) {}

	}

	do_search_if_needed = () => {
		const item_list = this.get_item_list();

		if (item_list) {
			let {prev_search_text, search_text} = this.state;
			if (prev_search_text != search_text) {
				this.update_search_results(search_text, prev_search_text);
				prev_search_text = search_text;
				this.setState({prev_search_text});
			}

			if (this.state && this.state.search_text == '') {
				if (!item_list._flags) {
					item_list._flags = {};
				}
				if (item_list._flags._remote_search_order) {
					item_list._flags._remote_search_order = undefined;
					this.refresh();
				}
			}
		}

	}

	// TBD.. try to simplify old model of searching..
	//	new model: window.g_searchBar will call update_search
	//	with new search text for global search.
	update_search = search_text => this.setState({search_text})

	// placeholders  : overridden by subclases
	
	show_item_header = (item) => null
	show_item_detail = () => null
	list_toolbar = () => null
	detail_toolbar = () => null
	detail_command = () => null
	list_legend = () => null
	onMount = () => {}	// overridden in some sub classes
	nop = () => {}
	fetched_older = () => {}
	update_search_results = (search_text, prev_search_text) => null

	//---- Utils

	redir_iid = iid => {
		iid = iid || this.find_next_iid();
		const {rid, tab} = this.props;
		log('listview', 'redir_iid', rid, tab, iid);
		go_replace('room', rid, tab, iid);
	}


	refresh = extra => {
		if (extra === undefined)
			extra = {};	// extra unused.remove. TBD
		if (this._isMounted) {
			log('listview', 'refresh', this.props, extra);
			this.setState({nrefresh: this.state.nrefresh + 1,
				...extra})
		}
	}

	reload = () => {	// called when there is new data from handlers
		log('listview', '+++++++reload+++++');
		this.refresh();
	}

	find_next_iid = () => {
		const {rid, tab} = this.props;
		return get_first_id(rid, tab);
	}

	remove_item = iid => {
		const {rid, tab} = this.props;
		const list = get_list(rid, tab);
		const id = parseInt(iid);

		list_rm_item(list, id);
		const new_iid = this.find_next_iid();

	}

	wait = () => (
		<div>
			<p>
				Fetching data from the server...
			</p>
			<ProgressSpinner/>
		</div>
	)

	paused = () => (
		<div>
			<p>
				This organization is currently paused.
				No data will be fetched until it is resumed.
			</p>
		</div>
	)
	
	no_items = () => {
		// nbsp words stretch width to full. 
		// easy alternatives aren't yielding.

		const blanks = new Array(100); 
		for (let i=0; i<100; i++)
			blanks[i] = i;
		return <div style={{marginTop: '10px'}}>
			{this.no_items_text()}
			{blanks.map(x => <span key={x}>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;
				</span>)}
		</div>
	}
	
	no_items_text = () => {
		return <div>There are no items in this list</div>
	}
	
	//---- Fetch
	
	reset_new = () => {
		// Can't use get_item_list, since it returns null if _new is set
		const {checkbox_col, rid, tab} = this.props;
		const list = this.get_item_list();

		list._flags._new = false;
		//if (checkbox_col && !list._flags.check_all_inited) {
		//	list.check_all_inited = true;
		//	this.check_all(true);
		//}
	}

	is_email = () => {
		const {tab} = this.props;
		return  (tab == 'unshared' || tab == 'shared')
	}

	get_new_item_ids = () => {
		const item_list = this.get_item_list();
		const page = item_list._flags.page;
		
		const cur_idlist = item_list._idlist;
		const prev_idlist = page._prev_idlist;
		
		const new_ids = cur_idlist.filter(x => !prev_idlist.includes(x));
		
		return new_ids;
	}

	fetched_page = (error, db, resp) => {
		const {rid, tab, iid} = this.props;

		log('listview', 'fetched_page', db.meta, this._isMounted);

		// TBD: handle error

		this.reset_new();

		const item_list = this.get_item_list();
		const page = item_list._flags.page;
		const meta = db.meta;

		page._fetching_page = false;
		page.last_dt = meta.last_dt;
		if (meta.total >= 0)
			page.total = meta.total;
		page.has_more = meta.has_more;

		// TBD: have server set this?
		const count = db.meta.count;
		const message = count ?  "Got " + count + " messages" :
					"No messages";
		growl(message);
		
		if (tab == 'unshared') {
			if (item_list._flags.is_check_all) {
				const new_ids = this.get_new_item_ids();
				new_ids.forEach(id => {
					const item = item_list._items[id];
					item.is_checked = true;
				});
			}
		}
		
		if (this._isMounted) {
			if (iid < 0)
				this.redir_iid();	// Use first item
			else
				this.refresh();
		}
	}

	clear_activity = (room, tab) => {
		const args = {cmd: 'mod_room',
				op: 'clear_room_activity', 
				rid: room.id, 
				val: tab};
		api( args );

		log('listview', 'clear_activity', args);
	}

	fetch_page = () => {
		const {rid, tab, iid} = this.props;
		const item_list = this.get_item_list();
		const page = item_list._flags.page;
		const search = page.search || '';
		const last_dt = page.last_dt;
		const kind = page.kind || '';

		if (page._fetching_page)
			return;

		page._fetching_page = true;
		page._prev_idlist = [...item_list._idlist];

		const args = {cmd: 'get_list', tab, rid, last_dt, search, 
				kind, page_size:page.size};
		api( args, this.fetched_page, this.fetched_page );

		log('listview', 'fetch_page', {last_dt, iid});
	}

	fetched_item = (error, db, resp) => {
		log('listview', 'fetched_item', db);

		const page = this.get_page();
		const req_iid = resp.request.iid;
		const {iid} = this.props;

		//  TBD: no need to remove it?
		// list_rm_ele(page._fetching_iids, iid);

		if (iid == req_iid)
			this.refresh();
		// else, user clicked on a diff item before we got email body
	}

	fetch_item = () => {
		const {rid, tab, iid} = this.props;
		const page = this.get_page();

		if (list_has_ele(page._fetching_iids, iid))
			return;

		page._fetching_iids.push(iid);

		const cmd = 'get_detail';
		const args = {cmd, rid, tab, iid};

		// if (window.g_idebug++ < 2)
		api( args, this.fetched_item );

		log('listview', 'fetch_item', iid);
	}
	//---- New?
	
	is_it_new = (room, tab, item) => {
		const activity = get_room_activity(room, tab);

		// date_zero "last_read" for the tab, when we logged in.
		// Every time we fetch items, last_read for tab is updated,
		//	so we need to rely on "last_read" at login
		//		(or when user clicks clear_all (tbd))
		// When we see item, _seen is set when we show item_detail.
		//	But tasks can be updated, even after they're "seen".
		//	catch that case also.

		return (activity && 
			item.date_updated > activity._flags.date_zero &&
		(item._seen === undefined || item._seen < item.date_updated));
		
		/**
		let dt_last_read = 0;
		if (activity) {
			if (Number.isInteger(activity.dt_last_read)) {
				dt_last_read = activity.dt_last_read;
			} else {
				dt_last_read = Date.parse(activity.dt_last_read);
			}
		}
		
		let dt_updated = item.dt_updated;
		if (dt_updated) {
			dt_updated = Date.parse(dt_updated);
		}
		
		return dt_updated > dt_last_read && item._seen === undefined;
		**/
	}

	//---- Get More

	get_more = () => {
		const flags = this.get_page();
		const fp = flags._fetching_page;
		log('listview', 'get_more', {fp});
		return <center className="get-more-list-header" >
		{this.get_page()._fetching_page ?
			<ProgressSpinner/>
			:
		<Button label="Fetch More"
			className="p-button-raised p-button-secondary"
			icon="pi pi-fw pi-angle-double-down"
			tooltip="Show More Emails"
			onClick={this.fetch_page}
		/>}
	</center>
	}

	//---- List
	
	key_down = e => {
		let delta = 0;
		if (e.keyCode == 38)	// up arrow
			delta = -1;
		if (e.keyCode == 40)	// down arrow
			delta = 1;

		if (!delta)
			return;

		const items = this.get_item_list();
		let {rid, tab, iid, command} = this.props;

		iid = parseInt(iid, 10);
		let index = items._order.indexOf(iid);

		log('listview', 'arrow move', delta, rid, tab, iid, index);

		index += delta;
		if (index < 0 || index == items._order.length)
			return;

		iid = items._order[index];
		go('room', rid, tab, iid);
	}

	go_to_next_item = () => {
		const items = this.get_item_list();
		let {rid, tab, iid, command} = this.props;

		iid = parseInt(iid, 10);
		let index = items._order.indexOf(iid);

		index += 1;
		if (index < 0 || index == items._order.length)
			return;

		iid = items._order[index];
		go('room', rid, tab, iid);
	}

	item_checked = (item, val) => {
		item.is_checked = val;
		this.refresh();
	}

	check_all = val => {
		const {rid, tab} = this.props;
		const list = get_list(rid, tab); 
		list._flags.is_check_all = val;
		foreach_list(list, item => {
			if ((item.is_private) && (val == true)) {
				return;
			}
			item.is_checked = val
		});
		this.refresh();
	}

	get_checked_ids = (extra) => {
		let mark_as_moved = true;
		if (extra) {
			if ('mark_as_moved' in extra) {
				mark_as_moved = extra['mark_as_moved'];
			}
		}

		const {rid, tab} = this.props;
		const list = get_list(rid, tab); 
		const ids = [];
		foreach_list(list, item => {
			if (item.is_checked && !item._being_moved) {
				if (mark_as_moved) {
					item._being_moved = true;
				}
				ids.push(item.id);
			}
		});
		return ids;
	}

	sel_check = e => {
		/* Check the currently selected item */
		const {checkbox_col, rid, tab} = this.props;
		const iid = e.currentTarget.dataset.iid;
		if (checkbox_col && iid >= 0) {
			const item = get_item(rid, tab, iid);
			item.is_checked = true;
		}
	}

	// Overwritten in shared emails
	get_thread_ids = id => []

	// Skipping showing threaded emails for now.
	show_list_item_tree = (id) => {
		// show thread parents also, if fetched and selected.
		//	if not, just the current item.
		const thread_ids = this.get_thread_ids(id);
		return <React.Fragment>
			{this.show_list_item(id)}
			{thread_ids.map( (id, index) => 
				this.show_list_item(id) )}
		</React.Fragment>
	}

	handle_scroll = (event) => {
		const {scrollHeight, scrollTop, clientHeight} = event.target;
		const scroll = scrollHeight - scrollTop - clientHeight
		
		if (scroll > 0) {
			// We are not at the bottom of the scroll content
		} else if (scroll == 0) {
			// We are at the bottom
		}
		console.log('SCROLL', scroll, scrollHeight, scrollTop, clientHeight);
	}
	
	show_list_item = id => {
		    const {rid, tab, iid, checkbox_col} = this.props;
		    const items = this.get_item_list();
		    const item = items._items[id];
		    const room = get_room(rid);

		    if (item === undefined) {
			if (tab != 'shared') {
				return (<div>This item (ID #{id}) has not been fetched yet</div>)
			} else {
				return ('')
			}
		    }
		    const is_new = this.is_it_new(room, tab, item);
		    let sel_class = (id == iid? 'sel-list-item' : '');
		    return (
	     <div className={"p-grid list-item hover-highlight " + sel_class} 
	     		key={id} 
			ref={el => {
				if ((id == iid) && el && !is_in_viewport(el)) {
					if (window.g_listView_save.clicked_checkbox) {
						window.g_listView_save.clicked_checkbox = false;
					} else {
						el.scrollIntoView(false);
					}
				}
			}}
		>

		{/* (optional/unshared) : Select Checkbox Col */}
		{checkbox_col && <div className="p-col-fixed checkbox-col">
			<Checkbox checked={item.is_checked} 
			    onChange={e => {
				window.g_listView_save.clicked_checkbox = true;
				this.item_checked(item, e.checked);
			    }}
				/>
			</div>}

		{/*  Show Item */}
		<div className="p-col p-col-undo-padding">
		    <Link to={go_url('room', rid, tab, id)}
			data-iid={id}
			onClick={this.sel_check} replace >
				{this.show_item_header(item, is_new)}
		    </Link>
		</div>
	     </div> )
	}
	
	list_view = command_output => {
		const {rid, tab, iid, command, checkbox_col,
				g_viewCount} = this.props;
		const items = this.get_item_list();
		
		const nitems = items ? items._order.length : 0;
		log('listview', 'list', 
			{rid, tab, nitems, command, g_viewCount});

		if (items == null) { 
			return this.wait();
		}

		const room = get_room(rid);
		const list = get_list(rid, tab); 

		const {search_text, remote_search_text} = this.state;

		const has_more = list._flags.page.has_more;

		list._flags._search_text = this.state.search_text;
		set_order(list);
		resort(list);

		return (
	<div className="p-col-12 p-md-6 list-view"
			onKeyDown={this.key_down}
			>
		{this.list_toolbar(items)}
		{command_output}
		<div className="list-legend list-item">
			{this.list_legend(list)}
		</div>
		<ScrollPanel className="list-view-scroll-panel">

		{/* Show Item List */}
		{items._order.map(id => this.show_list_item(id), this)}

		{/* If no items, show custom no items message */}
		{items._order.length == 0 && this.no_items()}
		
		{has_more && this.get_more()}
		
		</ScrollPanel>
	</div>
		)
	}

	print_if_set = () => {

		// 1. Print Menu Command would have set this.state.print
		// 2. this.show_item_detail would have set this.printEl
		//	somewhere. eg. EmailUtil.js: FullEmail
		// 3. this routine will call handlePrint to bring print dialog
		// 4. componentDidUpdate will reset this.state.print

		if (!this.state.print)
			return null;

		return (
			<ReactToPrint content={ () => this.printEl } >
			  <PrintContextConsumer>
			    {
			   	({ handlePrint }) => ( handlePrint() )
			    }
			  </PrintContextConsumer>
			</ReactToPrint>
		);
	}

	//---- Detail

	detail_view = command_output => {
		const item = this.get_current_item();
		if (!item) {
			if (ldb.data.org.paused) {
				return '';
			}
			// return this.wait();	
		}

		log('listview', 'detail', item);

		return (
	<div className="p-col-12 p-md-6 detail-view">
		{this.detail_toolbar(item)}
		{command_output}
		<ScrollPanel className="detail-view-scroll-panel" >
		{item && item.id ? this.show_item_detail(item) : this.no_items()}
		</ScrollPanel>
		{this.print_if_set()}
	</div>
		)
	}

	// ---- Commmand output

	get_command_output = () => {
		// Command Output is shown above both panes by default.
		//	Sometimes, in place command output like 
		//	SMS or Tags, might be displayed within List Panel
		//	or Detail Panel. We figure out based on command
		//	where to show.
		const {command} = this.props;
		const item = this.get_current_item() || {};
		// if (item)
			// item._seen = Date.now();
		const output = this.show_command(item);

		const co = {main: null, list_inplace: null, 
				detail_inplace:null};
		if (['new_sms'].indexOf(command)>=0)
			co.list_inplace = output;
		else if (['tag'].indexOf(command)>=0)
			co.detail_inplace = output;
		else
			co.main = output;
		// No detail_inplace commands for now..

		return co;
	}

	//---- Render

	render() {
		if (ldb.data.org.paused) 
			return this.paused();

		// window.g_searchBar.set_target_comp(this);
		const {rid, tab, iid} = this.props;
		log('listview', 'render', {rid, tab, iid});

		const command_output = this.get_command_output();

		return (
		<div>
			{command_output.main}
			<div className="p-grid">
			    {this.list_view(command_output.list_inplace)}
			    {this.detail_view(command_output.detail_inplace)}
			</div>
		</div>
		)
	}
}

export default ListView;
