import React, { Component } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {Link} from 'react-router-dom';

import {Focusable, Spacer, ModalCommand} from './Utils';
import {ldb, log, api, go, edate, edate5, map_list, go_url, growl, em, 
	drafts_tab, check_for_draft_shell, html2plain, 
	show_is_new, get_room, get_staff, get_my_feedback, time_diff,
	delete_draft_shell} from './Lib';
import {editorToolbar} from './EditTask';



// vsave_... => Vault Save for Drafts...
//	Currently saved encrypted in localstorage.. in future, in the cloud


//------------------ Unsaved Drafts  ------------------------

function get_vault_encryption_key() {
	const key = ldb.data.local_storage_key;
	
	return key;
}

class Vault {
	constructor() {
		this.key = null;
		this.data = '';
		this.encryptor = null;
	}
	init() {
		this.key = 'cw_unsaved_drafts_' + ldb.data.me.id;
		this.crypto_key = get_vault_encryption_key();
		this.encryptor = require('simple-encryptor')(this.crypto_key);
	}
	encode(obj) {
		const jdata = JSON.stringify(obj);
		const edata = this.encryptor.encrypt(jdata);
		
		return edata;
	}
	decode(buf) {
		const edata = this.encryptor.decrypt(buf);
		const data = JSON.parse(edata);

		return data;
	}
	get_base_key() {
		return this.key;
	}
	make_full_key(key) {
		const full_key = this.key + '_' + key;

		return full_key;
	}
	put(key, obj, encode=true) {
		const full_key = this.make_full_key(key);

		this.data = obj;
		try {
			const sdata = encode ? this.encode(obj) : obj;
			localStorage.setItem(full_key, sdata);

			return sdata;
		} catch(e) {
		}
		
		return null;
	}
	get(key, decode=true, full_key_provided=false) {
		const full_key = full_key_provided ? key : this.make_full_key(key);
		
		try {
			let vault = localStorage.getItem(full_key);
			if (vault == null) 
				return null;
			const sdata = decode ? this.decode(vault) : vault;

			return sdata;
		} catch(e) {
		}
		
		return null;
	}
	remove(key) {
		const full_key = this.make_full_key(key);
		
		try {
			//log('vault', 'Removing ' + full_key);
			localStorage.removeItem(full_key);
			//log('vault', 'Removed ' + full_key);
		} catch(e) {
		}
	}
}

var vault = new Vault();
window.g_vault = vault;		// init called from ldb.post_login

/* -- */

class DraftVault {
	constructor() {
		this.on = false;
	}
	turn_on() {
		log('vault', 'turn_on');
		
		this.on = true;
	}
	turn_off() {
		log('vault', 'turn_off');
		
		this.on = false;
	}
	genkey(draft, part) {
		const {rid, iid} = draft;
		const key = 'd_' + rid + '_' + iid + '_' + part;
		//log('vault', 'genkey', key);
		
		return key;
	}
	save(draft, part, data, encode=true) {
		if (!this.on)
			return;
		
		const key = this.genkey(draft, part);
		
		vault.put(key, data, encode);
		
		//log('vault', 'save', part, key, data);
		
		//draft.vsave_time = window.g_moment.now();
		//if (!draft.vsave_state)
		//	draft.vsave_state = 'unsaved';
	}
	get(draft, part, decode=true) {
		if (!this.on)
			return null;

		const key = this.genkey(draft, part);

		const data = vault.get(key, decode);
		
		//log('vault', 'get', part, key, data);
		
		return data;
	}
	remove(draft, part) {
		if (!this.on)
			return;
		
		const key = this.genkey(draft, part);
		
		vault.remove(key);

		//log('vault', 'remove', draft, part);
	}
	age(draft) {
		// Return the age of the draft in days
		
		log('vault', 'age', draft);
		
		const vsave_time = draft.vsave_time;
		
		if (vsave_time == null)
			return null;
		
		const gm = window.g_moment;
		const dur = gm.duration( gm().diff( gm(vsave_time) ) );
		return dur.days();
	}
	list() {
		// List all drafts
		
		if (!this.on)
			return;
		
		const keys = this.keys();
		
		keys.forEach( key => {
			const v = vault.get(key, true, true);
			log('vault', 'List drafts', key, v);
		});
	}
	keys() {
		// List all keys
		
		if (!this.on)
			return [];
		
		const keys = Object.entries(localStorage);
		
		const base_key = vault.get_base_key();

		let return_keys = [];
		
		keys.forEach( key_data => {
			const k = key_data[0];
			
			if (k.startsWith(base_key)) {
				return_keys.push(k);
			}
		});
		
		//log('vault', 'List keys', return_keys);
		
		return return_keys;
	}
	remove_all() {
		// Remove all drafts
		
		const keys = this.keys();
		
		keys.forEach( key => {
			log('vault', 'Remove All', key);
			localStorage.removeItem(key);
		});
	}
	get_all() {
		// Returns all drafts
		
		let drafts = [];
		
		const keys = this.keys();
		
		keys.forEach( key => {
			log('vault', 'Get All', key);
			
			if (!key.endsWith('draft')) {
				return;
			}
			
			let draft = vault.get(key, true, true);
			
			let f_draft = vget_draft(draft);
			
			drafts.push(f_draft);		
		});
		
		return drafts;
	}
	clean() {
		// Remove all drafts older than one week

		const drafts = this.get_all();
		
		log('vault', 'Clean', drafts);
		
		drafts.forEach( d => {
			const a = this.age(d);
			log('vault', 'Checking draft age', a);

			if (a > 7) {
				log('vault', 'Remove stale draft', d);
				
				this.remove(d, 'draft');
				this.remove(d, 'subject');
				this.remove(d, 'body');
				this.remove(d, 'version_data');
			}
		});
		
		log('vault', 'Clean complete');
	}
}

var dvault = new DraftVault();
window.g_dvault = dvault;



function clone2(obj) {
	return JSON.parse(JSON.stringify(obj));
}

function strip_attachments(draft) {
	draft.attach_items = draft.files.map(fitem => {
		return {name: fitem.payload.name,
			size: fitem.payload.size,
			kind: fitem.payload.kind
			};
	});
	draft.orig_files = [];
	draft.files = [];
	
	if (draft.attach_items.length > 0) {
		draft.alert_attach_items = true;
	}
	
	//log('vault', draft.attach_items);
	
	return draft;
}

function update_draft_number(draft) {
	let new_number = 1;
	if (draft.version_number) {
		new_number = draft.version_number + 1;
	}
	
	draft.version_number = new_number;
}

function vsave_draft(orig, remove_attachments=true) {
	if (!ldb.data.org.settings.save_emails_locally) {
		return;
	}
	
	update_draft_number(orig);
	
	let draft = clone2(orig);
	
	if (remove_attachments) {
		draft = strip_attachments(draft);
	}
	
	dvault.save(draft, 'draft', draft);

	vsave_draft_version_number(draft);

	//log('vault', 'save_draft', draft);
}

function vsave_draft_body(ndraft) {
	if (!ldb.data.org.settings.save_emails_locally) {
		return;
	}
	
	dvault.save(ndraft, 'body', ndraft.html);
	
	update_draft_number(ndraft);

	vsave_draft_version_number(ndraft);
	
	//log('vault', 'save_draft_body', ndraft);
}

function vsave_draft_subject(ndraft) {
	if (!ldb.data.org.settings.save_emails_locally) {
		return;
	}
		
	dvault.save(ndraft, 'subject', ndraft.subject);
	
	update_draft_number(ndraft);
		
	vsave_draft_version_number(ndraft);

	//log('vault', 'save_draft_subject', ndraft);
}

function vsave_draft_version_number(ndraft) {
	if (!ldb.data.org.settings.save_emails_locally) {
		return;
	}
	
	// TBD Encrypt here?
	const data = {'version_number': ndraft.version_number, 'vsave_time': window.g_moment.now()};
	dvault.save(ndraft, 'version_data', data);
	
	//log('vault', 'save_draft_version_number', ndraft);
}

function vrm_draft(draft) {
	if (!ldb.data.org.settings.save_emails_locally) {
		return;
	}
	
	log('vault', 'rm_draft', draft);

	dvault.remove(draft, 'draft');
	dvault.remove(draft, 'subject');
	dvault.remove(draft, 'body');
	dvault.remove(draft, 'version_data');
}

function vget_draft(draft) {
	let s_draft = dvault.get(draft, 'draft');
	
	if (!s_draft) {
		return null;
	}
	
	let s_subject = dvault.get(draft, 'subject');
	let s_body = dvault.get(draft, 'body');
	let s_version_data = dvault.get(draft, 'version_data');
	
	// TBD Handle missing elements
	
	if (s_subject !== null) {
		s_draft.subject = s_subject;
	}
	
	if (s_body !== null) {
		s_draft.html = s_body;
		
		let plain = html2plain(s_body);
		plain = plain.replace(/\[data:image\/.*?\]/gim, '');
		
		s_draft.plain = plain;
	}
	
	if (s_version_data !== null) {
		//log('vault', 'S_VERSION_DATA', s_version_data, s_version_data['version_number']);
		let s_version_number = s_version_data['version_number'];
		let s_vsave_time = s_version_data['vsave_time'];
		
		s_draft.version_number = s_version_number;
		s_draft.vsave_time = s_vsave_time;
	}
	
	return s_draft;
}

function vrecover_draft(draft) {
	let s_draft = vget_draft(draft);
	
	log('vault', 'recover_draft', draft, s_draft);
	
	if (!s_draft) {
		return;
	}
	
	s_draft.vsave_state = 'recovery';
	
	vsave_draft(s_draft);
	
	go('room', s_draft.rid, 'compose', s_draft.iid);
}



export {vsave_draft, vsave_draft_body, vsave_draft_subject, vrm_draft, vget_draft, vrecover_draft};
