import React, {Component, useReducer} from 'react'; 
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import {ScrollPanel} from 'primereact/scrollpanel';
import {InputText} from 'primereact/inputtext';
import {TabView, TabPanel} from 'primereact/tabview';
import {ListBox} from 'primereact/listbox';
import {SelectButton} from 'primereact/selectbutton';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDueDate,
	TaskCategory, TaskCommand, TaskSelectStaff,} from './EditTask';
import {show_name} from './TaskUtil';
import {ModalCommand, Spacer, Focusable, FakeLink} from './Utils';

import {ldb, log, gen_email_summary, task_category_default, regcomp,
	go_url, url2room, url2iid, go, em, fulldatetime,
	get_first_name, get_staff, get_room, set_order, resort,
	priorities, get_tag, pri_v2n, edate, env_tags, rel_url,
	task_due_date_delta_default, growl, api, pri_n2v, edate2,
	edate3, get_tag_by_me, is_tag_read, is_my_tag_read,
	show_is_new, unset_priority} from './Lib';

let saved_to_sid = null;
let saved_priority = null;
let saved_dt_due = null;


const getname = sid => ( sid == ldb.data.me.id ? 'Me' :
				get_first_name(get_staff(sid).name) )

const getemail = sid => ( get_staff(sid).email )

function StaffList(props) {
	const {rid, par, only_self, remove_nobody_all} = props;
	const to_sid = par.state.to_sid;

	const room = get_room(rid);
	set_order(room.staffs);
	resort(room.staffs);
	const order = room.staffs._order;
	let staffs = order.map(sid => ({name: getname(sid), email: getemail(sid), value: sid,
			sel: sid == to_sid} ));
	
	if (!remove_nobody_all) {
		staffs.push({name: 'Nobody', value:0, sel: to_sid == 0});
		staffs.push({name: 'All', value:-1, sel: to_sid == -1});
	}

	if (only_self) {
		staffs = [{name : getname(ldb.data.me.id), value: ldb.data.me.id, sel: ldb.data.me.id == to_sid}];
	}

	const min_staff_toggle = 10;

	if (staffs.length >= min_staff_toggle) {
		let options = [];
		let value = '';
		staffs.forEach( s => {
			options.push({'label': s.name, 'value': s.value});
			if (s.sel) {
				value = s.value;
			}
		});
		
		return (
			<ListBox value={value} options={options} onChange={(e) => par.setState({to_sid:e.value})} />
		);
	} else {
		return (
		<React.Fragment>
			{staffs.map((item,i)=> <Button key={i}
				label={item.name}
				title={item.email}
				onClick={e => {
					e.preventDefault();
					par.setState({to_sid:item.value});
				}}
		className={item.sel ? 'p-button-primary' : 'p-button-secondary'}
			/>)}
		</React.Fragment>
		)
	}
}

function Presets(props) {
	const my_presets = ldb.data.me.settings.tag_presets || [];

	const choicebuf = "I got this: Follow up : Reply : Read : Let's talk: Return to this later";
	const choices = choicebuf.split(':').map(x => x.trim()).concat(my_presets);
	// CSS: (white-space:nowrap) for phrases in span.preset 
	//	so add space afterwards to break line

	return (
	<div className="preset-wrap">
		{choices.map((choice,i) => <span className='preset' key={i}
			onClick={()=>props.par.setState({note:choice})}
				>
					<span>{choice}</span>
					{' '}
			</span>)}
	</div>
	);
}

function today() {
	return window.g_moment().toDate();
}

function Due({par}) {
	const choose = dt => par.setState({dt_due : dt});
	const m = window.g_moment;

	const presets = [
		{name: 'today', dt: m().toDate()},
		{name: 'tomorrow', dt: m().add(1, 'days').toDate()},
		{name: 'yesterday', dt: m().add(-1, 'days').toDate()},
		{name: 'next week', dt: m().add(1, 'week').toDate()},
		{name: 'next month', dt: m().add(1, 'month').toDate()},
		{name: 'next year', dt: m().add(1, 'year').toDate()},
		{name: 'none', dt: null},
	];

	return (
	<div >
		<Calendar value={par.state.dt_due}
			onChange={ e => choose( e.value ) }
			style={{zIndex:5000}}
				></Calendar>
		<br/>
		{presets.map( (item, i) => <span className="preset" key={i}
			onClick={e=>choose(item.dt)}
				>
					<span>{item.name}</span>
					{' '}
			</span>)}
	</div>
	);
}

class AddTag extends Focusable {
	constructor(props) {
		super(props);
		const note = 'Follow up';
		const priority = pri_n2v('Normal');
		const to_sid = ldb.data.me.id;
		this.state = {note, to_sid, priority, 
				dt_due: null, // today(),
				show_due: false,
				show_extra_options: false};

		regcomp(this, 'addTag');
	}

	onAdd = e => {
		const {to_sid, note, category, 
				priority, dt_due} = this.state;
		const {rid, item, close} = this.props;
		const args = {
			cmd: 'add_tag', 
			to_sid, note, priority, rid, eid: item.id,
			dt_due,
		}
		api( args, this.added, this.added );
		
		saved_to_sid = to_sid;
		saved_priority = priority;

		close();
	}

	added = (error) => {
		this.props.close();
	}

	close = e => {
		e.preventDefault();
		this.props.close();
	}

	render() {
		const {par, rid, close, mobile, is_my_mailbox} = this.props;
		const {priority, show_due, show_extra_options} = this.state;
		let submit = '';

		if (mobile) {
			par.action.handler = this.onAdd;
			submit = ' invisible';
		}

		return (
	<Card className="add-tag">
<div className="p-grid">

	<label className="p-col-2 desc">
		Tag:
	</label>
	<div className="p-col-10">
		<StaffList par={this} rid={rid} only_self={is_my_mailbox} />
	</div>

	<label className="p-col-2 desc">
		Note:
	</label>
	<InputText className="p-col-10"
		ref={inp =>  (this._focusEle = inp)}
		value={this.state.note} 
		placeholder=""
		onChange={e => this.setState({note: e.target.value})}
			/>

	<label className="p-col-2 desc">
		Presets:
	</label>
	<div className="p-col-10">
		<Presets par={this} />
	</div>	
	
	{(!show_extra_options) && <React.Fragment>
		<label className="p-col-2 desc" style={{padding: '0'}}>
			&nbsp;
		</label>
		<div className="p-col-10" style={{padding: '0'}}>
		<FakeLink onClick={e=>this.setState( {show_extra_options: true})} className="fade-link" style={{fontSize: 'small'}}>
			Show Options
		</FakeLink>
		</div>
	</React.Fragment>}
		

	{show_extra_options && <React.Fragment>
	{show_due && <React.Fragment>
		<label className="p-col-2 desc">
			Due:
		</label>
		<div className="p-col-10">
			<Due par={this} />
		</div>	
	</React.Fragment>}

	<label className="p-col-2">
		
	</label>
	<div className="p-col-5">
		<FakeLink onClick={e=>this.setState( {show_due: !show_due})} 
			icon="pi pi-fw pi-clock" className="fade-link" >
				Due
		</FakeLink>
		{em(1)}
		<Dropdown value={priority}
	    		options={priorities}
			onChange={ e => this.setState({ priority: e.value }) }
		/>
	</div>
	<div className="p-col-5"></div>
	</React.Fragment>}

	<div className="p-col-12">
		&nbsp;
	</div>

	<label className="p-col-2">
		
	</label>
	<div className={"p-col-10" + submit}>
		<Button label="Add" icon="pi pi-check" 
			onClick={this.onAdd}
			style={{marginRight: '.25em'}}/>
		{em(1)}
		<Button label="Cancel" icon="pi pi-times" 
			onClick={this.close}
			className="p-button-secondary"/>
	</div>

</div>
	{this.state.creating && <ProgressSpinner/>}
	</Card>
		);

	}
}

class ShowTag extends Component {
	constructor(props) {
		super(props);
		this.state = {working:false};

		regcomp(this, 'showTag');
	}

	onDone = () => {
		const {tag} = this.props;
		const args = {
			cmd: 'mod_tag', 
			tid: tag.id,
			op: 'done',
		}
		api( args, this.postDone, this.postDone );
		this.setState({working:true});
	}

	postDone = (error) => {
		this.setState({working:false});
	}

	sname = (sid,kls) => sid == ldb.data.me.id ? 'Me' : 
				show_name(sid, kls)

	get_mode = tag => {
		if (tag.dt_done)
			return 'done';
		if (tag.to_sid == ldb.data.me.id)
			return 'mine';
		return 'others';
	}

	is_viewed = tag => {
		if (!tag.dt_viewed)
			return '';
		
		let viewed_by = '';
		if (tag.to_sid)
			viewed_by = ' by ' +  getname(tag.to_sid);
		
		return (<span className="tag-viewed-indicator" title={"Viewed" + viewed_by + " at " + edate(tag.dt_viewed)}>
			<i className="pi pi-fw pi-eye"></i>
			</span>
		);
	}

	due_date = tag => {
		if (!tag.dt_due)
			return '';
		
		let title = 'Due at ' + edate3(tag.dt_due);
		
		return (<span className="tag-viewed-indicator" title={title}>
			<i className="pi pi-fw pi-clock"></i>
			</span>
		);
	}

	action = tag => {
		const mid = ldb.data.me.id;

		if (tag.dt_done)
			return <i 
	title={this.sname(tag.closer_sid) + ' on ' + edate(tag.dt_done)}
		className="pi pi-fw pi-check-circle tag-action-done"></i>;

		if (tag.to_sid == mid || tag.by_sid == mid) {
			//return <Checkbox
			//	tooltip="Mark as Done"
			//	tooltipOptions={{position: 'left'}}
			//	onClick={this.onDone}
			//	/>
			return <Button label=""
				icon="pi pi-fw pi-check"
				tooltip="Mark as Done"
				tooltipOptions={{position: 'left'}}
				className="p-button-secondary"
				onClick={this.onDone}
				/>;
		}
	}

	icon = (kmode, kpri) => this.state.working ? 
		<i className="pi pi-fw pi-spin pi-spinner"></i> :
		<i className={"pi pi-fw pi-tag tag-icon" + kmode + kpri}></i>

	render() {
		const {tag} = this.props;
		const mode = this.get_mode(tag);
		const from = this.sname(tag.by_sid);
		let to = this.sname(tag.to_sid, 'tag-to');

		const pri = pri_v2n(tag.priority);
		const viewed = this.is_viewed(tag);
		const due_date = this.due_date(tag);
		const kmode = ' tag-' + mode;
		const kpri = ' tag-' + pri;

		return (
<div className={"p-grid tag" + kmode} >
	<div className="p-col-3" title={edate(tag.dt_added)} >
		{this.icon(kmode, kpri)}
		{' '}
		<span className="tag-from" >
			{from}
			&rarr;
		</span>
		{to}
		<div>
		{viewed}
		{due_date}
		</div>
	</div>
	<div className="p-col-8 tag-note">
		{tag.note}
	</div>
	<div className="p-col-1">
		{this.action(tag)}
	</div>

</div>
		);
	}
}

class Untagged extends Component {
	constructor(props) {
		super(props);
		this.state = {working:false};

		regcomp(this, 'untagged');
	}

	onNoTags = e => {
		e.preventDefault();

		const {env} = this.props;
		const args = {
			cmd: 'mod_env', 
			eid: env.id,
			op: 'notags',
		}
		api( args, this.postNoTags, this.postNoTags );
		this.setState({working:true});
	}

	postNoTags = (error) => {
		this.setState({working:false});
	}

	render() {
		const {env, mobile} = this.props;
		const add_url = mobile ? 'add_tag/' : 'tag/';
		// log("tag", "untagged", this);

		return (
<div className="p-grid untagged">
	<div className="p-col-4">
		<i className="pi pi-fw pi-tag"></i>
		Tag not set
		{this.state.working &&
			<i className="pi pi-fw pi-spin pi-spinner"></i>}
	</div>
	<div className="p-col-4 tag-note">
		<Link to="tag/" onClick={this.onNoTags}>
			<i className="pi pi-fw pi-times"></i>
			Not Needed
		</Link>
	</div>
	<div className="p-col-4">
		<Link to={rel_url(add_url)} >
			<i className="pi pi-fw pi-plus"></i>
			Add Tag
		</Link>
	</div>

</div>
		);
	}
}

function is_active_tag(tag) {
	return !tag.dt_done && tag.to_sid == ldb.data.me.id;
}

function is_active_tag_by_me(tag) {
	return !tag.dt_done && tag.by_sid == ldb.data.me.id;
}

function ShowTags({par, rid, mobile, env}) {

	if (env.is_untagged)
		return <Untagged env={env} mobile={mobile} />;

	if (!env.tag_ids)
		return null;

	const tags = env_tags(env);

	return (
	<div className="tags">
		{tags.map( (tag,i) => <ShowTag tag={tag} key={i} /> )}
	</div>
	);
}

function taglegend(kind) {
	return (
<div className="p-grid mytags-legend">
	<div className="p-col-6">
		Note
	</div>
	<div className="p-col-4">
		Room
	</div>
	<div className="p-col-1">
		Due
	</div>
	<div className="p-col-1">
		{kind == 'for_me' ? 'By' : 'To'}
	</div>
</div>
	);
}

function is_tag_viewed(tag) {
	if (!tag.dt_viewed)
		return '';

	if (tag.to_sid == ldb.data.me.id)
		return '';
	
	let viewed_by = '';
	if (tag.to_sid)
		viewed_by = ' by ' +  getname(tag.to_sid);
	
	return (<span className="tag-viewed-indicator" title={"Viewed" + viewed_by + " at " + edate(tag.dt_viewed)}>
		{em(0.5)}<i className="pi pi-fw pi-eye"></i>
		</span>
	);
}

function tag_unread_indicator(tag) {
	if (is_tag_read(tag)) {
		return null;
	}
	
	return show_is_new(true);
}

function tag_icons(tag, parreload) {
	return null;
	
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
	
	const loc = get_tag(tag.id) ? 'tags' : 'tags_by_me';
	if (loc == 'tags_by_me') {
		return null;
	}
	
	const is_favorite = tag.mark_data.favorite;
	const return_later = tag.mark_data.return_later;
	
	const toggle_favorite_badge = () => {
		const new_value = is_favorite ? false : true;
		const args = { cmd: 'mark_tag', tid: tag.id, loc: loc, field: 'favorite', value: new_value };

		api( args, (error, db) => {
			forceUpdate();
		});
	};

	const toggle_return_later_badge = () => {
		const new_value = return_later ? false : true;
		const args = { cmd: 'mark_tag', tid: tag.id, loc: loc, field: 'return_later', value: new_value };

		api( args, (error, db) => {
			forceUpdate();
		});
	};
	
	const favorite_icon = is_favorite ? 'pi-star' : 'pi-star-o';
	const return_later_icon = return_later ? 'fa-bookmark' : 'fa-bookmark-o';

	const f_action_word = is_favorite ? 'Unmark' : 'Mark';
	const r_action_word = return_later ? 'Unbookmark' : 'Bookmark';

	return (<span><i className={"pi pi-fw " + favorite_icon}
			title={f_action_word + " as a favorite tag"}
			onClick={toggle_favorite_badge}>
		</i>
		<i className={"fa fa-fw " + return_later_icon}
			title={r_action_word + " as a tag to revisit later"}
			onClick={toggle_return_later_badge}>
		</i>
	</span>);
}

function tag_priority_icon(tag) {
	const mid = ldb.data.me.id;
	let pri = unset_priority;	
	
	if (!tag.dt_done)
		pri = tag.priority;
	
	if (pri == unset_priority)
		return null;
	
	const priority_name = pri_v2n(pri);
	if (priority_name == 'Normal')
		return null;

	const kpri = ' tag-' + priority_name;
	
	return (<span><i className={"pi pi-fw pi-tag env-tag" + kpri}></i> <span className={kpri} style={{fontSize:'small'}}>{priority_name} Priority</span></span>)
}

function tag_priority_class(tag) {
	let pri = unset_priority;	
	
	if (!tag.dt_done)
		pri = tag.priority;
	
	if (pri == unset_priority)
		return '';
	
	const kpri = ' tag-background-' + pri_v2n(pri);
	
	return kpri;
}

function mini_staff(sid) {
	if (!sid)
		return null;

	const staff = get_staff(sid);
	const title = staff.name + ' (' + staff.email + ')';

	return <span title={title}>
		{getname(sid)}
	</span>;
}

function ShowRoomTag(props) {
	const {tag, i, kind, room_col, reload} = props;

	const [refresh, setRefresh] = React.useState(0);
	const treload = () => setRefresh(refresh+1);
	const [markDone, setMarkDone] = React.useState(false);
	if (markDone)
		return null;

	let by = '';
	if (tag.by_sid) {
	}
	let to = '';
	if (tag.to_sid) {
		const to_staff = get_staff(tag.to_sid);
		to = <span title={to_staff.name + ' (' + to_staff.email + ')'}>{getname(tag.to_sid)}</span>;
	}
	
	let klass = i % 2 ? ' oddrow' : ' evenrow';
	
	const date_klass = is_tag_read(tag) ? '' : ' unread-tag-date';

	const m = window.g_moment;
	const today = m();
	/*
	const ndays = m(tag.dt_due).diff(today, 'days');

	if (ndays < 0)
		klass += ' due-past';
	else if (ndays == 0)
		klass += ' due-today';
	*/
	
	const room = get_room(tag.rid);
	const rname = room ? room.name : '';

	const tab = room.is_mailbox ? 'unshared' : 'shared';

	return (
<div className={"p-grid mytags-row pointer " + klass} key={"mytag_" + i}>
	<div className="p-col-1">
		{tag_priority_icon(tag)}
		{tag_icons(tag, treload)}
		{tag_unread_indicator(tag)}
	</div>
	<div className="p-col-1 mytags-person" onClick={e => go('room', tag.rid, tab, tag.eid)}>
		{mini_staff(kind == 'for_me' ? tag.by_sid : tag.to_sid)}
	</div>
	<div className="p-col-3 mytags-note" onClick={e => go('room', tag.rid, tab, tag.eid)}>
		{tag.note}
		{room_col && <span style={{fontSize: 'small'}}><br/>({rname})</span>}
	</div>
	<div className={"p-col-2 mytags-date" + date_klass} onClick={e => go('room', tag.rid, tab, tag.eid)}>
		{edate3(tag.dt_added)}
	</div>
	<div className={"p-col-2 mytags-date" + date_klass} onClick={e => go('room', tag.rid, tab, tag.eid)}>
		{edate3(tag.dt_due)}
	</div>
	{tag_env(tag)}
</div>
	);
}

function tag_env(tag) {
	const einfo = JSON.parse(tag.einfo_json);
	if (einfo.subject === undefined)
		return <div className="p-col-3"> </div>;

	log("tag", "env", einfo);
	const efrom = einfo.from[0] || {name:'', email:''};
	const title = efrom.name + ' (' + efrom.email+ ')';
	
	const room = get_room(tag.rid);
	const rname = room ? room.name : '';
	
	const tab = room.is_mailbox ? 'unshared' : 'shared';

	return <React.Fragment>
		<div className="p-col-3 mytags-env-from" onClick={e => go('room', tag.rid, tab, tag.eid)}>
			<div className="p-grid" style={{padding: '0'}} title={title}>
				<div className="p-col-4" style={{padding: '0'}}>
					{get_first_name(efrom.name)}:
				</div>
				<div className="p-col-8 mytags-env-subject" style={{padding: '0'}}>
					{einfo.subject}
				</div>
			</div>
		</div>
	</React.Fragment>;
}

function show_room_tags(rid, i, kind, self_tags, reload) {
	const root = kind == 'by_me' ? ldb.data.tags_by_me : ldb.data.tags;
	const room = get_room(rid);
	const rname = room.name;
	
	let rtags = [];
	root._order.forEach(function(tid) {
		const tag = kind == 'by_me' ? get_tag_by_me(tid) : get_tag(tid);
		
		if ((kind != 'by_me') && (tag.to_sid != ldb.data.me.id))
			return;
		
		if ((kind == 'by_me') && (tag.by_sid != ldb.data.me.id))
			return;
		
		if ((self_tags) && (tag.by_sid != ldb.data.me.id))
			return;
		
		if (tag.dt_done)
			return;

		if (tag.rid == rid)
			rtags.push(tag);
	});

	if (rtags.length == 0)
		return null;
	
	return (<div key={i}>
<div className="p-grid mytags-room-header">
	<div className="p-col-12">
		{rname}
	</div>
</div>
	{rtags.map( (tag,j) => <ShowRoomTag tag={tag} key={j} i={j}
			kind={kind} room_col={false} reload={reload} /> )}
	</div>);
}

function show_tags_by_priority(priority, i, kind, self_tags, reload) {
	const root = kind == 'by_me' ? ldb.data.tags_by_me : ldb.data.tags;
	
	let ptags = [];
	root._order.forEach(function(tid) {
		const tag = kind == 'by_me' ? get_tag_by_me(tid) : get_tag(tid);
		
		if ((kind != 'by_me') && (tag.to_sid != ldb.data.me.id))
			return;
		
		if ((kind == 'by_me') && (tag.by_sid != ldb.data.me.id))
			return;
		
		if ((self_tags) && (tag.by_sid != ldb.data.me.id))
			return;
		
		if (tag.dt_done)
			return;
		
		const pri = pri_v2n(tag.priority);
		
		if (pri == priority)
			ptags.push(tag);
	});

	if (ptags.length == 0)
		return null;
	
	return (<div key={i}>
<div className="p-grid mytags-room-header">
	<div className="p-col-12">
		{priority} Priority
	</div>
</div>
	{ptags.map( (tag,j) => <ShowRoomTag tag={tag} key={j} i={j}
			kind={kind} room_col={true} reload={reload} /> )}
	</div>);
}

function my_tags_header(kind) {
	const person = (kind == 'for_me') ? 'By' : 'To';

	return (<div className="p-grid mytags-legend">
		<div className="p-col-1">
		</div>
		<div className="p-col-1">
			{person}
		</div>
		<div className="p-col-3">
			Note
		</div>
		<div className="p-col-2">
			Created
		</div>
		<div className="p-col-2">
			Due Date
		</div>
		<div className="p-col-3">
			Tagged Email
		</div>
	</div>);
}

function TagsForMe(props) {
	const {sort_by, target, self_tags} = props;
	
	let data_list = (target == 'by_me') ? ldb.data.tags_by_me : ldb.data.tags;
	
	if (data_list._idlist.length == 0)
		return 'None';
	
	if (sort_by == 'room') {
		const root = ldb.data.rooms;
		root._tags_flags = {'_flags': {'sortby': 'name'}};
		
		set_order(root, '_tags_flags');
		resort(root, 'room', '_tags_flags');
		
		const order = ldb.data.rooms._tags_flags._order;
		
		return (
		<div className="tagtab">
			{my_tags_header(target)}
			
			<ScrollPanel className="mytags">
				{order.map( (rid,i) => show_room_tags(rid, i, target, self_tags, props.reload) )}
			</ScrollPanel>
		</div>
		);
	} else if (sort_by == 'date') {
		const order = [...data_list._idlist].reverse();
		
		return (
		<div className="tagtab">
			{my_tags_header(target)}
			
			<ScrollPanel className="mytags">
				{order.map( (tid,j) => {
					const tag = (target == 'by_me') ? get_tag_by_me(tid) : get_tag(tid);
					
					if (tag.dt_done)
						return null;
					
					if ((self_tags) && ((tag.to_sid != ldb.data.me.id) || (tag.by_sid != ldb.data.me.id)))
						return null;
					
					return (<ShowRoomTag tag={tag} key={j} i={j}
						kind={target} room_col={true} reload={props.reload} />);
				})}
			</ScrollPanel>
		</div>
		);
	} else if (sort_by == 'priority') {
		const priorities = ['Urgent', 'High', 'Normal', 'Low'];
		
		return (
		<div className="tagtab">
			{my_tags_header(target)}
			
			<ScrollPanel className="mytags">
				{priorities.map( (priority,i) => show_tags_by_priority(priority, i, target, self_tags, props.reload) )}
			</ScrollPanel>
		</div>
		);
	}
}

function get_num_tags(kind, self_tags) {
	const root = kind == 'by_me' ? ldb.data.tags_by_me : ldb.data.tags;
	
	let tags = [];
	root._order.forEach(function(tid) {
		const tag = kind == 'by_me' ? get_tag_by_me(tid) : get_tag(tid);
		
		if ((kind != 'by_me') && (tag.to_sid != ldb.data.me.id))
			return;
		
		if ((kind == 'by_me') && (tag.by_sid != ldb.data.me.id))
			return;
		
		if ((self_tags) && (tag.by_sid != ldb.data.me.id))
			return;
		
		if (tag.dt_done)
			return;

		tags.push(tag);
	});
	
	return tags.length;
}

window.g_tags_sortby = 'room'; 

function MyTags(props) {
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);
	const [index, setIndex] = React.useState(0);
	const [sortBy, setSortBy] = React.useState(window.g_tags_sortby);
	
	const for_me_num = get_num_tags('for_me', false);
	const by_me_num = get_num_tags('by_me', false);
	const self_num = get_num_tags('for_me', true);
	
	const sort_by_options = [
		{'name': 'Room', 'value': 'room'},
		{'name': 'Date Created', 'value': 'date'},
		{'name': 'Priority', 'value': 'priority'},
	];
	
	const buttons = (
	<div className="p-grid" style={{marginBottom:'1em'}}>
		<div className="p-col-1" style={{textAlign:'right', paddingTop:'15px'}}>
			Sort By: 
		</div>
		<div className="p-col-11">
			<SelectButton value={sortBy} options={sort_by_options} optionLabel="name" onChange={e => {
				window.g_tags_sortby = e.value;
				setSortBy(e.value);
			}} />
		</div>
	</div>
	);
	
	if ((ldb.data.me.settings.show_tags_help) && (ldb.data.tags._idlist.length == 0) && (ldb.data.tags_by_me._idlist.length == 0)) {
		return (
		<Card>
			<p>
				This page displays the tags you have been assigned by other staff members, as well at the tags you have assigned to others. You currently have no tags assigned to you.
			</p>
		</Card>
		);
	}

	return (
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)} >
		<TabPanel header={"Tags for me (" + for_me_num + ")"} 
				leftIcon="fa fa-fw fa-level-down">
			{buttons}
			<TagsForMe {...props} reload={reload} sort_by={sortBy} target="for_me" self_tags={false} />
		</TabPanel>
		<TabPanel header={"Tags by me (" + by_me_num + ")"}
				leftIcon="fa fa-fw fa-level-up">
			{buttons}
			<TagsForMe {...props} reload={reload} sort_by={sortBy} target="by_me" self_tags={false} />
		</TabPanel>
		<TabPanel header={"Self tags (" + self_num + ")"}
				leftIcon="fa fa-fw fa-exchange">
			{buttons}
			<TagsForMe {...props} reload={reload} sort_by={sortBy} target="for_me" self_tags={true} />
		</TabPanel>
	</TabView>
	);
}


function minitag(tid, i, sel) {
	const tag = get_tag(tid);
	const room = get_room(tag.rid);
	const rname = room ? room.name : '';
	
	const klass = sel == tag.eid ? 'mini-sel' : '';
	return (
	<li key={i} >
		<Link to={go_url('room', tag.rid, 'shared', tag.eid)} 
					className={klass} >
			&#x25b8;
			{' '}
			{tag.note}
			<div className="tag-room">
				({rname})
			</div>
		</Link>
	</li>
	);
}

class MiniTags extends Component {
	componentDidUpdate() {
		const room = url2room();
		if (room)
			window.g_setTitle(room.name);	

		const sel = document.querySelector('.mini-sel');
		if (sel)
			sel.scrollIntoView(false);
	}

	render() {
		const root = ldb.data.tags;
		root._flags._search_text = this.props.search;
		root._flags._filter_fn = is_active_tag;
		set_order(root);
		resort(root);
		const order = root._order;

		if (root._idlist.length == 0)
			return 'None';
		
		const sel = url2iid();

		return (
		<ScrollPanel className="mini-list">
		<ul>
			{order.map((tid, i) => minitag(tid, i, sel))}
		</ul>
		</ScrollPanel>
		);
	}
}

export {AddTag, ShowTags, ShowTag, MyTags, MiniTags, StaffList};
