import React, {Component, useState, useRef, useEffect} from 'react'; 
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import {Menu} from 'primereact/menu';
import {confirmDialog} from 'primereact/confirmdialog';
import {OrderList} from 'primereact/orderlist';
import DnDList from 'react-dnd-list';
import {Toolbar} from 'primereact/toolbar';
import {Panel} from 'primereact/panel';
import {ScrollPanel} from 'primereact/scrollpanel';
import {InputText} from 'primereact/inputtext';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';
import {TabView, TabPanel} from 'primereact/tabview';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDueDate,
	TaskCategory, TaskCommand, TaskSelectStaff,} from './EditTask';
import {show_name} from './TaskUtil';
import {ModalCommand, Spacer, Focusable, FakeLink, lazyFocus, 
	HelpInfo} from './Utils';

import {ldb, log, gen_email_summary, task_category_default, regcomp,
	go_url, url2room, url2iid, go, em, fulldatetime, gen_xid,
	get_first_name, get_staff, get_room, set_order, resort,
	priorities, get_tag, pri_v2n, edate, env_tags, rel_url,
	task_due_date_delta_default, growl, api, pri_n2v, edate2,
	edate3, get_tag_by_me, is_tag_read, is_my_tag_read, init_list,
	map_list, foreach_list, copy_to_clipboard,
	show_is_new} from './Lib';

const empty_item = () => ({note: '', done: false})

const mk_cklog = (op='updated') => `${op} by ${ldb.data.me.name} on ${edate()}`

function new_checklist() {
	return {
		name: `List #${gen_xid().substr(0,4).toUpperCase()}`,
		version: 1,
		position:0,	// highest position is displayed first
		style: '',
		logs: [mk_cklog('created')],
		items: Array(7).fill(0).map(empty_item),
	};
}

function get_cklist(cid) {
	return ldb.data.checklists._items[cid];
}


// UNUSED: Move Mode, Drag and Drop >>---
function DnDListItem({dnd, item}) {
	const [active, setActive] = useState(false);
	const [note, setNote] = useState(item.note);

	log("checklist", "li", item);
	return (
	<li
		style={{ ...dnd.item.styles, ...dnd.handler.styles }}
		className={dnd.item.classes}
		ref={dnd.item.ref}
		{...dnd.handler.listeners}
		>
		<Inplace active={active} onToggle={e=>setActive(e.value)} >
			<InplaceDisplay>
				Edit {item.index} {note}
			</InplaceDisplay>
			<InplaceContent>
				<InputText value={note}
					onChange={e => setNote(e.target.value)}
					/>
			</InplaceContent>
		</Inplace>
	</li>
	);
}

// UNUSED
function DragDropList({items, update}) {
	return (
	<div>
	Drag and Drop items to rearrange.
	<br/>
	<ul>
	<DnDList 
	items={items} itemComponent={DnDListItem} setList={update} />
	</ul>
	</div>
	);
}

// Move Mode, Drag and Drop <<---

//--- New code>>

const onCreate = (e, my) => {
	const data = new_checklist();
	const args = { cmd: 'mod_checklist', op: 'create', my, data};
	api( args );
}

// debug only. unused
const onReset = (e, reload) => {
	const args = { cmd: 'mod_checklist', op: 'delete_all'};
	api( args, resp => {
		ldb.data.checklists = init_list();
		reload();
	});
}

function addLogDone(cid, item) {
	const cklist = get_cklist(cid);
	const cdata = cklist.data;
	const action = item.done ? 'Completed' : 'Unchecked';
	let logmsg = `${action} "${item.note}"`;
	cdata.logs.unshift( mk_cklog(logmsg) );
}

const onSave = (cid, logmsg='updated') => {
	log('checklist', 'onSave', cid);
	const cklist = get_cklist(cid);
	const cdata = cklist.data;
	if (!cklist._mod) {
		log('checklist', 'not modified', cid);
		return;
	}
	// TBD: if _mod is false, skip saving.
	cdata.logs.unshift( mk_cklog(logmsg) );
	const args = { cmd: 'mod_checklist', op: 'update', cid, 
				data:cdata };
	api( args );	// ignore callback, returned data will refresh

	setModFlag(cid, false);
	growl('Saved.');

}

const setModFlag = (cid, flag=true) => {
	const cklist = get_cklist(cid);
	cklist._mod = flag;

	if (flag) {
		if (!cklist._timer)
			cklist._timer = setTimeout( 
				()=>onSave(cid), 
				60*1000			// 60 seconds
			);
	}
	else {
		if (cklist._timer)
			clearTimeout(cklist._timer);
		cklist._timer = null;
	}
}

const save = () => {
	if (JSON.stringify(cdata) == orig) {
		log("checklist", "skip save, unchanged", cdata, orig);
		return;
	}
}

const onDelete = cid => {
	log('checklist', 'onDelete', cid);
	const args = { cmd: 'mod_checklist', op: 'delete', cid };
	api( args );	// ignore callback, returned data will refresh
}

const onMoveFirst = cid => {
	const cklist = get_cklist(cid);
	const cdata = cklist.data;
	let hipos = cdata.position;

	foreach_list(ldb.data.checklists, ckl => {
		if (ckl.sid == cklist.sid && ckl.position > hipos)
			hipos = ckl.position;
	});

	hipos++;
	cdata.position = hipos;

	log('checklist', 'onMoveFirst', cid, hipos);
	return onSave(cid, 'Moved to First');
}

const show_logs = logs => {
	const loglist = (
	<ol>
		{logs.map((log,i) => <li key={i}>{log}</li>)}
	</ol>
	);
	confirmDialog({
		header: 'Logs',
		message: loglist,
		acceptClassName: 'hide',
		rejectClassName: 'hide',
		className: 'checklist-logs',
	});
	/*
	return (
	<HelpInfo header="Log" className="normal">
	<ol>
		{logs.map((log,i) => <li key={i}>{log}</li>)}
	</ol>
	</HelpInfo>
	);
	*/
}

const list_to_clipboard = cdata => {
	let buf = `  ${cdata.name}  \n\n`;

	cdata.items.forEach( (item, i) => {
		buf += `${i+1}. ${item.note}`;
		if (item.done)
			buf += `        (done)`;
		buf += '\n';
	});

	copy_to_clipboard(buf);

	growl('Copied to Clipboard.');
}

function ListSet({my}) {
	const [refresh, setRefresh] = useState(0);

	// Edit Item. eitem = {cid, index}.
	//	index: -1=> list name, 0:n : list item
	const [eitem, setEitem] = useState({});	

	const reload = () => setRefresh(refresh+1);
	const title = my ? 'My Lists' : 'Shared Lists';

	useEffect(() => {
		window.g_lists_reload = reload;

		return function cleanup() {
			const cid = eitem.cid;
			log("checklist", "ListSet.cleanup", eitem, cid);
			// Ensure this cid wasn't just deleted
			const cklist = get_cklist(cid);
			if (cid && cklist) {
				setEitem({});
				onSave(cid);
			}
			window.g_lists_reload = null;
		};
	}, [refresh]);
	
	const root = ldb.data.checklists;
	root._flags._filter_fn = cklist => (my ? cklist.sid : !cklist.sid)
	set_order(root);
	resort(root);
	const order = root._order;
	log("checklist", "List", refresh, my, order);


	const MainToolbar = () => <Toolbar
		left={<strong>{title}</strong>}
		right={
	<div >
		<Button label="New"
			className="p-button-warning "
			icon='pi pi-plus'
			tooltip="Create New List"
			tooltipOptions={{position: 'left'}}
			onClick={e => onCreate(e, my)}
		/>
	</div>
		}
		/>
	
	const ensureBlanks = cid => {
		const cdata = get_cklist(cid).data;
		const blanks = cdata.items.filter(item => (!item.note) );

		if (blanks.length < 2)
			cdata.items.push(empty_item());
	}
	
	const saveEdit = (cid, index) => {
		log('checklist', 'saveEdit', cid, eitem.cid, index);

		if (eitem.cid != cid && eitem.cid) 
			onSave(eitem.cid);

		if (cid)
			ensureBlanks(cid);

		setEitem({cid, index});
	}
	
	const ShowItem = ({cid, item, index}) => {
		const [done, setDone] = useState(item.done);
		return (
		<li key={index} value={index+1}
			>
		<div className="p-d-flex">
			<div className="p-mr-2 flex-grow"
				onClick={e => saveEdit(cid, index)} 
			>
				{item.note}
			</div>
			<div className="p-mr-2">
				<Checkbox 
					checked={item.done}
					onChange={ e => {
						item.done = e.checked;
						item.when = new Date();
						setDone(item.done);
						setEitem({cid, index:-2});
						setModFlag(cid);
						addLogDone(cid, item);
					}}
				/>
			</div>
		</div>
		</li>
		);
	}
	
	const EditItem = ({cid, item, index}) => {
		const [note, setNote] = useState(item.note);
		return (
		<li key={index} value={index + 1}>
			<InputText ref={lazyFocus}
				placehoder="________"
				onChange={e=> {
					item.note = e.target.value;
					setNote(item.note);
					setModFlag(cid);
				}}
			onKeyDown={e => {
				if (e.key === 'Enter') {
					const cdata = get_cklist(eitem.cid).data;
					if ((eitem.index+1) == 
							cdata.items.length)
						cdata.items.push(empty_item());

					saveEdit(eitem.cid, eitem.index+1);
				}
				else if (e.key === 'Escape') 
					saveEdit(0, 0);
			}}
				value={note}
			/>
		</li>
		);
	}
	
	const ShowName = ({cid}) => (
		<div onClick={e => saveEdit(cid, -1)}>
			{get_cklist(cid).data.name}
		</div>
	)
	
	const EditName = ({cid}) => {
		const cdata = get_cklist(cid).data;
		const [name, setName] = useState(cdata.name);
		return (
		<div >
			<InputText ref={lazyFocus}
				placehoder="Name"
				onChange={e=> {
					cdata.name = e.target.value;
					setName(cdata.name);
					setModFlag(cid);
				}}
			onKeyDown={e => {
				if (e.key === 'Enter' || e.key === 'Escape') 
					saveEdit(0, 0);
			}}
				value={name}
			/>
		</div>
		);
	}

	const editOrShowItem = (cid, item, i) => (
		(eitem.cid == cid && eitem.index == i) ?
			<EditItem key={i} cid={cid} item={item} index={i} /> :
			<ShowItem key={i} cid={cid} item={item} index={i} />
	)
	
	const ShowList = ({cid}) => {
		const cklist = get_cklist(cid);
		const cdata = cklist.data;
		// const [refmenu, setRefmenu] = useState(null);
		const refmenu = useRef(null);

		const idmenu = `ckl_menu_${cid}`;
		const mitems = [
		{
			label: 'Copy',
			icon: 'pi pi-copy',
			command: e => list_to_clipboard(cdata),
		},
		{
			label: 'Log',
			icon: 'pi pi-info',
			command: e => show_logs(cdata.logs),
		},
		{
			label: 'Delete',
			icon: 'pi pi-times',
			command: e => onDelete(cid),
		},
		];

		if (ldb.data.checklists._order[0] != cid) {
			mitems.unshift(
				{
			label: 'Move First',
			icon: 'pi pi-fast-backward',
			command: e => onMoveFirst(cid),
				}
			);
		}

		return (
		<div className="checklist p-mr-2 p-shadow-7">
				<div className="p-d-flex ckl-name pointer">
				<div className="p-mr-2 flex-grow">
		{(eitem.cid == cid && eitem.index == -1) ?
			<EditName cid={cid} /> : <ShowName cid={cid} />}
				</div>
				<div className="p-mr-2">
		{cklist._mod && <Button label=""
			icon='pi pi-save'
			tooltip="Save"
			onClick={e => onSave(cid)}
		/>}
		<Menu model={mitems} popup ref={refmenu} id={idmenu} />
		<Button label="" icon="pi pi-bars" 
			className="p-button p-button-text"
			onClick={e=>refmenu.toggle(e)}
			aria-controls={idmenu}
			aria-haspopup />
				</div>
				</div>

				<ol>
		{cdata.items.map( (item, i) => {
			// Show unchecked items first.
			if (item.done)
				return null;
			return editOrShowItem(cid, item, i);
			})}
		{cdata.items.map( (item, i) => {
			// Show checked items .
			if (!item.done)
				return null;
			return editOrShowItem(cid, item, i);
			})}
				</ol>
		</div>
		);
	}

	return (
	<div className="listtab">
		<MainToolbar />
		<div className="hide">
			{eitem.cid}.{eitem.index} 
		</div>
		<ScrollPanel className="mytags">
	<div className="p-d-flex p-flex-wrap">
		{order.map(cid => <ShowList key={cid} cid={cid} />)}
	</div>
		</ScrollPanel>
	</div>
	);
}

function Lists(props) {
	const [index, setIndex] = useState(0);

	return (
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)} >
		<TabPanel header="My Lists" 
				leftIcon="fa fa-fw fa-level-down" >
			<ListSet {...props} my={true} />
		</TabPanel>
		<TabPanel header="Shared Lists"  
				leftIcon="fa fa-fw fa-level-up">
			<ListSet {...props} my={false} />
		</TabPanel>
	</TabView>
	);
}


export {Lists};
