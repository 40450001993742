import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {SelectButton} from 'primereact/selectbutton';
import {ProgressSpinner} from 'primereact/progressspinner';

import {ldb, log, api, go, edate, edate3, map_list, go_url,
		get_room, get_staff, foreach_dict, is_test_server,
		save_growl_notification} from './Lib';

//------------------ NotificationLogs  ------------------------

/* This component shows Staff member's logs.
 * If staff user is org admin, a Select toggle lets user choose to show
 #	user's logs, or all users of the org's logs.
 * User's logs are in ldb.data.logs
 *	Org logs are in ldb.data.org_logs
 *	Design alternatives: 
 *	a. Keep all logs in same tree: ldb.data.logs.
 *	b. Keep data separate for org logs and user logs, despite duplication
 *	Opting for (b) to avoid edge cases of "refresh" to get latest logs 
 *	or earlier logs, and not have gaps.
 */

class NotificationLogs extends Component {
	constructor() {
		super();
		this.state = {error_logs: false};
	}

	log_root = () => {
		this.state.error_logs = ldb.data.growl_notifications ? ldb.data.growl_notifications : [];
		return this.state.error_logs;
	}

	componentDidMount() {
	}

	componentDidUpdate() {
	}
	
	generate_random_log = () => {
		const kind = 'success';
		const title = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 20);
		const body = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 50);
		
		save_growl_notification(kind, title, body);

		this.setState({});
	}

	generate_random_error_log = () => {
		const kind = 'error';
		const title = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 20);
		const body = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 50);
				
		save_growl_notification(kind, title, body);

		this.setState({});
	}

	logs_legend = () => <div className="p-grid legend bold">
		<div className="p-col-2">
			Date
		</div>
		<div className="p-col-1">
			Kind
		</div>
		<div className="p-col-3">
			Title
		</div>
		<div className="p-col-6">
			Message
		</div>
	</div>
	
	show_toolbar = () => {
		let toolbar = '';
		if (is_test_server()) {
			toolbar = <Toolbar
				left={
			<Button label="Generate Random Log" 
				className="p-button-text p-button-raised"
				onClick={this.generate_random_log} />
				}
				right={
			<Button label="Generate Random Error Log" 
				className="p-button-text p-button-raised"
				onClick={this.generate_random_error_log} />
				}
				/>
		}

		return toolbar;
	}

	show_logs = () => {
		const logs = this.log_root();
		logs._order = logs;
		return <div>
		{this.logs_legend()}
		{logs.map((entry, index) => {
			
			const klass = (entry.kind == 'error') ? 'notification-log-error' : 'notification-log-success';
			
			return <div className={'p-grid ' + klass} key="notification_log_{index}">
				<div className="p-col-2">
					{edate3(entry.dt)}
				</div>
				<div className="p-col-1">
					{entry.kind}
				</div>
				<div className="p-col-3">
					{entry.title}
				</div>
				<div className="p-col-6">
					{entry.body}
				</div>
			</div>
			})}
		<hr/>
		</div>
	}

	render() {

		log('logs', 'Notification Logs');
		
		return (
<div>
	{this.show_toolbar()}
	{this.show_logs()}
</div>
		)
	}
}

export {NotificationLogs};
