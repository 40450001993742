import React, { Component } from 'react';
import {Button} from 'primereact/button';
import './Documentation.css';
import {ldb, log, get_previous_versions,
	mbox_read_perm, mbox_write_perm,
	any_room_has_team_email, go} from './Lib';

export class Documentation extends Component {

	constructor() {
		super();
		this.state = {show_previous_versions: false};
	}

	toggle_previous_versions = () => {
		const new_value = this.state.show_previous_versions ? false : true;
		this.setState({show_previous_versions: new_value});
	}

	show_previous_versions = () => {
		if (this.state.show_previous_versions) {
			const previous_versions = get_previous_versions();
			
			return (
				<div>
				{previous_versions.map(version => this.display_version(version))}
				</div>
			);
		} else {
			return (
				<Button label="Previous Versions"
				onClick={this.toggle_previous_versions}
				style={{margin:'10px 2px', 'fontSize':'x-small'}}
				/>
			);
		}
	}

	display_version = (version) => {
		return (
			<div>
			<h5>
			Version {version.summary},
			{' '}
			{version.when}
			</h5>
			</div>
		);
	}

	// Keep terms synced up with 
	// 	.../server/onboard/templates/onboard/terms_text.html
	terms = () => (
			<div>
			<hr/>
<iframe src="https://www.taginbox.com/terms.html" title="Terms of Service" border="0" frameborder="0" cellspacing="0" style={{width:'100%', height:'600px'}}></iframe>
 	</div>
	)

	pre_login = () => (<div>
<h1>Welcome</h1>
<p>
TagInbox helps you breeze through email overload.
</p>
<p>
Please visit <a href="https://www.taginbox.com">https://www.taginbox.com</a> to learn more.
</p>
	</div>)

	post_login = () => (<div>
<h1>Welcome</h1>
<p>
TagInbox helps you breeze through email overload.
</p>
<p>
Please visit <a href="https://www.taginbox.com">https://www.taginbox.com</a> to learn more.
</p>
{this.display_version(ldb.data.sys.version)}

	</div>)

	render() {
		const html = ldb.data.me == null ? 
				this.pre_login() : 
				this.post_login();

		return <div className="ui-g">
			<div className="ui-g-12">
				<div className="card docs">
					{html}

<hr/>
<h1>Contact Us</h1>
TagInbox Corporation<br/>
4205 148th Ave NE, Suite 104<br/>
Bellevue, WA 98004<br/>
425 943 9451<br/>
					{this.terms()}
					
					<hr/>
					<Button label="Diagnostics"
						onClick={e => go('settings', 'diag')}
						style={{margin:'10px 2px', 'fontSize':'x-small'}}
					/>
				</div>
			</div>
		</div>;

	}
}

export class MailboxMonitoringInfo extends Component {

	constructor() {
		super();
		this.state = {};
	}

	render() {
		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();

		const has_team_email = any_room_has_team_email();
		
		return <div className="ui-g">
			<div className="ui-g-12">
				<div className="card docs">

{write_perm && <div>
<p>
	You are currently in "Normal Mailbox Monitoring Mode". Please contact your org admin if you would like to change your mode.
</p>
</div>}


{read_perm && (!write_perm) && <div>

<p>
	Welcome to TagInbox!
</p>
<p>
	Your account is restricted, as you chose NOT to have your INBOX automatically organized by TagInbox.
</p>
<p>
	<b>What can I do with TagInbox?</b>
	<br/>
	You can view activity and shared emails in client rooms, respond to tags and tasks, and send shared emails out from client rooms to clients. You can also view emails from your inbox in the unshared emails tab.
</p>
<p>
	<b>What can't I do with TagInbox?</b>
	<br/>
	The email in your inbox is not automatically organized by client room. You also cannot transfer unshared emails to shared emails for a client room. You cannot create client rooms, as well.
</p>
<p>
	<b>How do I get the full benefits of TagInbox?</b>
	<br/>
	Please contact your org admin if you would like to change your mode.
</p>
</div>}

{(!read_perm) && (!write_perm) && <div>

<p>
	Welcome to TagInbox!
</p>
<p>
	Your account is restricted, as TagInbox does NOT access your mailbox
</p>
<p>
	<b>What can I do with TagInbox?</b>
	<br/>
	You can view activity and shared emails in client rooms, as well as respond to tags and tasks.
</p>
{(has_team_email) && <p>
	<b>What can't I do with TagInbox?</b>
	<br/>
	The email in your inbox is not accessible via TagInbox. You cannot transfer unshared emails to shared emails for a client room You cannot create client rooms, as well. You cannot send emails to clients using your own email account. However, if you are a member of a client room that also has a staff member who has been marked as a team email account, then you can send and reply to client emails using that staff member's email address.
</p>
}
{(!has_team_email) && <p>
	<b>What can't I do with TagInbox?</b>
	<br/>
	The email in your inbox is not accessible via TagInbox. You cannot transfer unshared emails to shared emails for a client room, or send emails from the system to clients. You cannot create client rooms, as well.
</p>}
<p>
	<b>How do I get the full benefits of TagInbox?</b>
	<br/>
	Please contact your org admin if you would like to change your mode.
</p>
</div>}

				</div>
			</div>
		</div>;

	}
}

